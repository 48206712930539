import { AlertPromptBase } from './alert-prompt-base';

export class AlertPromptReminderSuccess extends AlertPromptBase<string> {
  override titleTC? = 'REMINDERS.SUBMITTED_TITLE';
  override subtitleTC? = '';
  override cancelTextTC? = '';
  override continueTextTC? = 'REMINDERS.DONE_BUTTON';
  override disclaimerTC? = '';
  override disclaimerRecaptchaTC? = '';
}
