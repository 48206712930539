<main class="container py-4 px-3" [appInert]="showTermsAndPrivacyModal">
  <header>
    <div class="d-flex flex-column flex-sm-row justify-content-between pt-3">
      <h1 class="text-primary">
        {{ 'COADMIN.TITLE' | translate }}
      </h1>
      <app-remind-me-button></app-remind-me-button>
    </div>

    <p class="text-tertiary my-3">
      {{
        'COADMIN.SUBTITLE'
          | translate : { coadminAdditionalVaccinesMax: coadminAdditionalVaccinesMax }
      }}
    </p>

    <!-- Max Additional Vaccines exceeded error -->
    <div *ngIf="isMaxExceeded">
      <h5 id="disclaimer-error" class="d-inline-block">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.953 2C6.465 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.493 2 11.953 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.567 4 11.953 4C16.391 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z"
            fill="white"
          />
          <path d="M11 7H13V14H11V7ZM11 15H13V17H11V15Z" fill="white" />
        </svg>

        {{
          'COADMIN.SELECTED_VACCINES_MAX_EXCEEDED'
            | translate : { count: selectedServices.length, deselectCount: deselectCount }
        }}
      </h5>
    </div>

    <!-- Service errors -->
    <ng-container *ngIf="errors">
      <div *ngFor="let error of errors">
        <h5 id="disclaimer-error" class="d-inline-block">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.953 2C6.465 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.493 2 11.953 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.567 4 11.953 4C16.391 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z"
              fill="white"
            />
            <path d="M11 7H13V14H11V7ZM11 15H13V17H11V15Z" fill="white" />
          </svg>

          {{ error.reason }}
        </h5>
      </div>
    </ng-container>
  </header>

  <!-- Loading Indicator -->
  <app-loading-list-select *ngIf="loading$ | async"></app-loading-list-select>

  <ng-container *ngIf="(loading$ | async) === false">
    <section
      [class.first-section]="first"
      *ngFor="let service of services; let i = index; let first = first"
    >
      <div class="pb-3 d-flex justify-content-between" accordion-heading>
        <h2 class="text-primary m-0 p-0">{{ service.categoryNameTC | translate }}</h2>
        <button class="btn btn-sm btn-clear-primary m-0 p-0" (click)="toggleItem(i)" *ngIf="!first">
          <div *ngIf="!isOpenSections[i]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_12542_88358)">
                <path
                  d="M8.66634 4.66536H7.33301V7.33203H4.66634V8.66536H7.33301V11.332H8.66634V8.66536H11.333V7.33203H8.66634V4.66536ZM7.99967 1.33203C4.31967 1.33203 1.33301 4.3187 1.33301 7.9987C1.33301 11.6787 4.31967 14.6654 7.99967 14.6654C11.6797 14.6654 14.6663 11.6787 14.6663 7.9987C14.6663 4.3187 11.6797 1.33203 7.99967 1.33203ZM7.99967 13.332C5.05967 13.332 2.66634 10.9387 2.66634 7.9987C2.66634 5.0587 5.05967 2.66536 7.99967 2.66536C10.9397 2.66536 13.333 5.0587 13.333 7.9987C13.333 10.9387 10.9397 13.332 7.99967 13.332Z"
                  fill="var(--vax-primary)"
                />
              </g>
              <defs>
                <clipPath id="clip0_12542_88358">
                  <rect width="16" height="16" fill="transparent" />
                </clipPath>
              </defs>
            </svg>
            <span class="ms-1 text-medium">
              {{ 'BUTTON.EXPAND' | translate }}
            </span>
          </div>
          <div *ngIf="isOpenSections[i]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M6.11454 10.8279L7.99988 8.94254L9.88521 10.8279L10.8279 9.88521L8.94254 7.99988L10.8279 6.11454L9.88521 5.17188L7.99988 7.05721L6.11454 5.17188L5.17188 6.11454L7.05721 7.99988L5.17188 9.88521L6.11454 10.8279Z"
                fill="var(--vax-primary)"
              />
              <path
                d="M7.99967 14.6654C11.6757 14.6654 14.6663 11.6747 14.6663 7.9987C14.6663 4.3227 11.6757 1.33203 7.99967 1.33203C4.32367 1.33203 1.33301 4.3227 1.33301 7.9987C1.33301 11.6747 4.32367 14.6654 7.99967 14.6654ZM7.99967 2.66536C10.9403 2.66536 13.333 5.05803 13.333 7.9987C13.333 10.9394 10.9403 13.332 7.99967 13.332C5.05901 13.332 2.66634 10.9394 2.66634 7.9987C2.66634 5.05803 5.05901 2.66536 7.99967 2.66536Z"
                fill="var(--vax-primary)"
              />
            </svg>
            <span class="ms-1 text-medium">
              {{ 'BUTTON.COLLAPSE' | translate }}
            </span>
          </div>
        </button>
      </div>
      <app-list-select
        [collapse]="!isOpenSections[i]"
        [isAnimated]="true"
        [isCardStyle]="true"
        [isMultiSelect]="true"
        [items]="getItems(service)"
        [listCategoryTC]="service.categoryNameTC"
        [selectedCode]="selectedServiceCode"
        (itemSelected)="setItem($event)"
      ></app-list-select>
    </section>
  </ng-container>

  <app-footer
    class="mt-5 d-block"
    [leftButtonName]="leftButtonName"
    (leftButtonClicked)="leftButtonClick()"
    [rightButtonName]="rightButtonName"
    [rightButtonDisabled]="isMaxExceeded"
    (rightButtonClicked)="rightButtonClick()"
  ></app-footer>
</main>

<div class="container text-center" *ngIf="!suppressTermsAndPrivacy">
  <div class="d-flex flex-row justify-content-center align-items-center">
    <button
      class="btn btn-link-primary text-medium"
      (click)="showTerms()"
      [innerText]="'APPOINTMENT.SEARCH.TERMS_CONDITIONS_BUTTON' | translate"
    ></button>

    <div class="divider"></div>

    <button
      class="btn btn-link-primary text-medium"
      (click)="showPrivacy()"
      [innerText]="'APPOINTMENT.SEARCH.PRIVACY_POLICY_BUTTON' | translate"
    ></button>
  </div>
</div>

<app-footer-branding></app-footer-branding>

<app-terms-and-privacy-modal
  *ngIf="showTermsAndPrivacyModal"
  [modalType]="modalType"
  [@fadeIn]="showTermsAndPrivacyModal"
  (dismissModal)="dismissTermsAndPrivacyModal()"
  (emitShowTerms)="showTerms($event)"
  (emitShowPrivacy)="showPrivacy($event)"
></app-terms-and-privacy-modal>
