import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RECAPTCHA_RETRY_LIMIT, RecaptchaActionType } from '../../constants/general';

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  public readonly recaptchaActionReserve = RecaptchaActionType.RESERVE;
  public readonly recaptchaActionRemind = RecaptchaActionType.REMIND;

  public recaptchaKey = '';
  public recaptchaError = '';
  public recaptchaRetryCount = 0;
  public recaptchaRetryLimit = RECAPTCHA_RETRY_LIMIT;

  constructor() {
    this.recaptchaKey = environment.recaptchaKey;
  }

  setError(error: string) {
    this.recaptchaRetryCount += 1;
    this.recaptchaError = error;
  }

  clearError() {
    this.recaptchaRetryCount = 0;
    this.recaptchaError = '';
  }
}
