import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { AppointmentService } from 'src/app/common/services/appointment/appointment.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { AppointmentDetailCardComponent } from 'src/app/components/appointment-detail-card/appointment-detail-card.component';
import {
  AppointmentReservationResponse,
  AppointmentSelection,
} from 'src/app/common/model/appointment';
import { RouteService } from 'src/app/common/services/route/route.service';
import { Recaptcha } from 'src/app/common/model/recaptcha';
import { VaxError, VaxErrorDetail } from 'src/app/common/model/error';
import { RecaptchaService } from 'src/app/common/services/recaptcha/recaptcha.service';
import { ServiceErrorCodes, getEmbedded } from 'src/app/common/constants/general';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { HeroComponent } from 'src/app/components/hero/hero.component';
import { Subscription } from 'rxjs';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { RemindMeButtonComponent } from 'src/app/components/remind-me-button/remind-me-button.component';

@Component({
  selector: 'app-appointment-review',
  imports: [
    CommonModule,
    TranslatePipe,
    FooterComponent,
    AppointmentDetailCardComponent,
    HeroComponent,
    RemindMeButtonComponent,
  ],
  templateUrl: './appointment-review.component.html',
  styleUrls: ['./appointment-review.component.scss'],
})
export class AppointmentReviewComponent implements OnInit, OnDestroy {
  localeSubscription: Subscription = new Subscription();
  loadingObj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading$: Observable<boolean>;
  set loading(isLoading: boolean) {
    this.loadingObj.next(isLoading);
  }
  get loading(): boolean {
    return this.loadingObj.getValue();
  }

  isEmbedded = false;
  selectedVaccineName?: string;
  selectedAppointment?: AppointmentSelection;
  safeRecaptchaHtml: SafeHtml = '';
  suppressSearchHeader = false;
  recaptchaRetryLimit = this.recaptchaService.recaptchaRetryLimit;
  get recaptchaRetryCount() {
    return this.recaptchaService.recaptchaRetryCount;
  }
  get recaptchaError() {
    const err = <any>this.recaptchaService.recaptchaError;

    if (!err) {
      return '';
    }

    if (err.details && err.details.length > 0) {
      return err.details[0].reason;
    }

    return '';
  }

  get errors(): VaxErrorDetail[] | undefined {
    const { errors } = this.appointmentService;
    return errors?.filter((error) => !error.displayInline);
  }

  constructor(
    private appointmentService: AppointmentService,
    private routeService: RouteService,
    private recaptchaService: RecaptchaService,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private environmentService: EnvironmentService,
    private titleService: Title
  ) {
    this.loading$ = this.loadingObj.asObservable();
    this.isEmbedded = getEmbedded();
    this.suppressSearchHeader = this.environmentService.suppressSearchHeader;

    // Recaptcha Terms and Conditions handling
    const translatedRecaptchaHtml = this.translateService.instant(
      'APPOINTMENT.REVIEW.RECAPTCHA_DISCLAIMER'
    );
    this.safeRecaptchaHtml = this.sanitizer.bypassSecurityTrustHtml(translatedRecaptchaHtml);

    if (this.appointmentService.selectedAppointment) {
      this.selectedAppointment = this.appointmentService.selectedAppointment;
    }

    // listen for locale changed so we can update the time UI with the proper locale format
    this.localeSubscription = this.environmentService.localeChanged.subscribe(() => {
      // retranslate vaccine instant translations if the locale changes
      this.setSelectedVaccineTranslations();
    });
  }

  ngOnInit(): void {
    if (!getEmbedded()) {
      this.titleService.setTitle(this.translateService.instant('PAGES.REVIEW.TITLE'));
    }

    this.setSelectedVaccineTranslations();
  }

  leftButtonClick() {
    this.appointmentService.clearErrors();

    const router = this.routeService.getRouter();
    router.navigate([this.routeService.getReservationRoute()]);
  }

  /**
   *
   * @param event The ReCaptcha token that needs to be included in the reserveAppointment request
   */
  rightButtonClick(event: Recaptcha) {
    this.loading = true;

    this.appointmentService.clearErrors();

    // Only allow a certain amount of recaptcha retries
    if (this.recaptchaRetryCount >= this.recaptchaRetryLimit) {
      return;
    }

    // Set the recaptcha token
    if (event && this.appointmentService.reservation) {
      this.appointmentService.reservation.token = event.token;
      this.appointmentService.reservation.action = event.action;
    }

    // Call reserve
    this.appointmentService?.reserveAppointment()?.subscribe({
      next: (resp: AppointmentReservationResponse) => {
        this.recaptchaService.clearError();

        this.loading = false;

        if (this.appointmentService.reservation && resp?.confirmationCode) {
          this.appointmentService.reservation.confirmationCode = resp.confirmationCode;
        }

        this.appointmentService.clearRetryState(); // clear retry state
        const router = this.routeService.getRouter();
        router.navigate([this.routeService.getConfirmationRoute()]);
      },
      error: (error: any) => {
        this.loading = false;

        if (error.error && error.error.subCode) {
          this.handleServiceError(error.error);
        } else {
          this.handleServiceError(error);
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.recaptchaService.clearError();

    if (this.localeSubscription) {
      this.localeSubscription.unsubscribe();
    }
  }

  handleServiceError(error?: VaxError) {
    console.log('Vax Error being handled: ', error);
    const router = this.routeService.getRouter();

    // No error passed if reserve/hold fails, or 500 error
    if (!error || error === undefined || (error as any).status === 500) {
      this.appointmentService.setRetryState(this.selectedAppointment?.location.locationGroupId); // set that we're in retryState
      router.navigate([this.routeService.getReservationErrorRoute()]); // Route to reservation error screen
      return;
    }

    // Process the error
    const { details } = this.appointmentService.processError(error);

    // Set errors
    this.appointmentService.setErrors(details);

    // Handle routing and retryState based on specific error subcodes
    if (error.subCode === ServiceErrorCodes.SUBCODE_APPOINTMENT_NOT_AVAILABLE) {
      this.appointmentService.setRetryState(this.selectedAppointment?.location.locationGroupId); // set that we're in retryState
      router.navigate([this.routeService.getReservationErrorRoute()]);
    } else if (error.subCode === ServiceErrorCodes.SUBCODE_INCOMPATIBLE_VACCINES) {
      this.appointmentService.setRetryState(this.selectedAppointment?.location.locationGroupId); // set that we're in retryState
      router.navigate([this.routeService.getCoadminRoute()]);
    } else if (error.subCode === ServiceErrorCodes.SUBCODE_PRESCRIPTION_REQUIRED_ERROR) {
      this.appointmentService.setRetryState(this.selectedAppointment?.location.locationGroupId); // set that we're in retryState
      router.navigate([this.routeService.getReservationRoute()]);

      // Scroll to the bottom so the user sees the errors
      setTimeout(() => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
      }, 100);
    }
  }

  private setSelectedVaccineTranslations() {
    const { selectedVaccineName } = this.environmentService;
    if (selectedVaccineName) {
      this.selectedVaccineName = this.translateService.instant(selectedVaccineName);
    }
  }
}
