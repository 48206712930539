import { Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { APPOINTMENT_ITEM_WIDTH } from 'src/app/common/constants/general';
import { LocationIntegrationLevelBaseComponent } from '../location-integration-level-base/location-integration-level-base.component';

@Component({
    selector: 'app-location-integration-level-three',
    imports: [CommonModule, TranslatePipe, LocationIntegrationLevelBaseComponent],
    templateUrl: './location-integration-level-three.component.html',
    styleUrls: ['./location-integration-level-three.component.scss']
})
export class LocationIntegrationLevelThreeComponent extends LocationIntegrationLevelBaseComponent {
  @ViewChild('appointmentRow', { static: false }) appointmentRowRef!: ElementRef | null;
  @ViewChild(LocationIntegrationLevelBaseComponent)
  locationCardBaseComponent!: LocationIntegrationLevelBaseComponent;

  // Called if locale changes so times are in the proper locale
  reinitialize() {
    this.setAppointmentTimes();
  }

  public override setState() {
    const cardContainer = this.locationCardBaseComponent.cardRefNativeElement;
    const cardComputedStyle = window.getComputedStyle(cardContainer);
    const appointmentRowContainer = this.appointmentRowRef?.nativeElement;
    const appointmentRowComputedStyle = appointmentRowContainer
      ? window.getComputedStyle(appointmentRowContainer)
      : null;
    const gap = appointmentRowComputedStyle
      ? appointmentRowComputedStyle.getPropertyValue('column-gap').split(' ')[0]
      : '0px';
    const horizonalPadding =
      parseInt(cardComputedStyle.getPropertyValue('padding-left')) +
      parseInt(cardComputedStyle.getPropertyValue('padding-right'));

    const containerWidth = cardContainer.offsetWidth - horizonalPadding;
    const numAppointments = this.filteredAppointments.length;
    this.numAppointmentsPerRow = Math.floor(
      containerWidth / (APPOINTMENT_ITEM_WIDTH + Number(gap.slice(0, gap.length - 2)))
    );
    this.truncatedNumAppointments = numAppointments - this.numAppointmentsPerRow;
  }
}
