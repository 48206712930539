import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { getEmbedded } from '../common/constants/general';
import { AnalyticsEvent } from '../common/model/event';
import { ListSelectItem } from '../common/model/list-select';
import { Service, ServiceGroup } from '../common/model/service';
import { EnvironmentService } from '../common/services/environment/environment.service';
import { EventService } from '../common/services/event/event.service';
import { RouteService } from '../common/services/route/route.service';
import { ModalType } from '../common/types/modal-type';
import { trigger, transition, useAnimation } from '@angular/animations';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { FADE_IN_ANIMATION, FADE_OUT_ANIMATION } from '../common/constants/animations';
import { FooterBrandingComponent } from '../components/footer-branding/footer-branding.component';
import { FooterComponent } from '../components/footer/footer.component';
import { ListSelectComponent } from '../components/list-select/list-select.component';
import { TermsAndPrivacyModalComponent } from '../components/terms-and-privacy-modal/terms-and-privacy-modal.component';
import { InertDirective } from '../directives/inert/inert.directive';
import { AppointmentSelection } from '../common/model/appointment';
import { AppointmentService } from '../common/services/appointment/appointment.service';
import { CoadminResponse } from '../common/model/coadmin';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoadingListSelectComponent } from '../components/loading-list-select/loading-list-select.component';
import { VaxErrorDetail } from '../common/model/error';
import { RemindMeButtonComponent } from '../components/remind-me-button/remind-me-button.component';

@Component({
  selector: 'app-coadmin-selection',
  imports: [
    CommonModule,
    ListSelectComponent,
    FooterComponent,
    TranslatePipe,
    forwardRef(() => TermsAndPrivacyModalComponent),
    FooterBrandingComponent,
    CollapseModule,
    InertDirective,
    LoadingListSelectComponent,
    RemindMeButtonComponent,
  ],
  templateUrl: './coadmin-selection.component.html',
  styleUrls: ['./coadmin-selection.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [useAnimation(FADE_IN_ANIMATION)]),
      transition(':leave', [useAnimation(FADE_OUT_ANIMATION)]),
    ]),
  ],
})
export class CoadminSelectionComponent implements OnInit, OnDestroy {
  loadingObj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading$: Observable<boolean>;

  coadminAdditionalVaccinesMax = Infinity;
  isOpenSections: boolean[] = [];
  selectedAppointment?: AppointmentSelection;
  selectedServiceCode?: string; // the primary selected service code
  selectedServices: Array<Service> = []; // the additional co admin service codes
  services: Array<ServiceGroup> | undefined = [];
  showTermsAndPrivacyModal = false;
  suppressTermsAndPrivacy = false;
  modalType: ModalType = 'prebooking-privacy';

  constructor(
    private routeService: RouteService,
    private environmentService: EnvironmentService,
    private translateService: TranslateService,
    private eventService: EventService,
    private titleService: Title,
    private appointmentService: AppointmentService
  ) {
    this.loading$ = this.loadingObj.asObservable();
    this.selectedServiceCode = this.environmentService.selectedService?.serviceCode;
    if (this.appointmentService.selectedAppointment) {
      this.selectedAppointment = this.appointmentService.selectedAppointment;
      this.coadminAdditionalVaccinesMax =
        this.appointmentService.selectedAppointment.location.coadminAdditionalVaccinesMax;
    }
  }

  get deselectCount(): number {
    if (this.selectedAppointment && this.selectedServices.length > 0) {
      return (
        this.selectedServices.length -
        this.selectedAppointment.location.coadminAdditionalVaccinesMax
      );
    }
    return 0;
  }

  get errors(): VaxErrorDetail[] | undefined {
    const { errors } = this.appointmentService;
    return errors?.filter((error) => !error.displayInline);
  }

  get isMaxExceeded(): boolean {
    if (this.selectedAppointment) {
      return (
        this.selectedAppointment.location.coadminAdditionalVaccinesMax <
        this.selectedServices.length
      );
    }
    return false;
  }

  get leftButtonName(): string {
    return this.translateService.instant('BUTTON.BACK');
  }

  get rightButtonName(): string {
    return this.translateService.instant('COADMIN.INFORMATION_BUTTON');
  }

  set loading(isLoading: boolean) {
    this.loadingObj.next(isLoading);
  }

  ngOnInit() {
    this.getCoadminServices();

    if (!getEmbedded()) {
      this.titleService.setTitle(this.translateService.instant('PAGES.COADMIN.TITLE'));
    }

    this.suppressTermsAndPrivacy = this.environmentService.suppressTermsAndPrivacy;
  }

  ngOnDestroy() {
    this.appointmentService.clearErrors();
  }

  getCoadminServices() {
    const { selectedAppointment } = this.appointmentService;
    if (!selectedAppointment) {
      return;
    }

    this.loading = true;

    this.appointmentService.getCoadminVaccines(selectedAppointment.locationId).subscribe({
      next: (resp: CoadminResponse) => {
        this.services = resp.availableServices;
        this.loading = false;

        this.setOpenSections();
        this.setSelectedServices();
      },
      error: (error: any) => {
        this.loading = false;

        this.appointmentService.selectedAdditionalVaccines = undefined;

        // Coadmin is not required so auto-advance to the next view
        const path = this.routeService.getReservationRoute();
        const router = this.routeService.getRouter();
        router.navigate([path]);
      },
    });
  }

  public getItems(service: ServiceGroup) {
    return service.services.map((item) => {
      return new ListSelectItem(
        item.serviceNameTC,
        item.serviceDescriptionTC,
        this.selectedServices?.some(
          (service) => service && service.serviceCode === item.serviceCode
        ),
        item.serviceCode
      );
    });
  }

  toggleItem(index: number) {
    this.isOpenSections[index] = !this.isOpenSections[index];
  }

  public leftButtonClick() {
    const router = this.routeService.getRouter();
    router.navigate([this.routeService.getAppointmentsRoute()]);
  }

  public rightButtonClick() {
    const path = this.routeService.getReservationRoute();
    this.appointmentService.selectedAdditionalServices = this.selectedServices;

    this.appointmentService.selectedAdditionalVaccines = this.selectedServices
      .filter((service) => service.vaccines?.length === 1) // Only include services with one vaccine
      .map((service) => service.vaccines[0]); // Map to the single vaccine

    if (this.selectedServices.length > 0) {
      // Analytics event
      const eventTimestamp = DateTime.local().toUTC().toString();
      const analyticsEvent: AnalyticsEvent = {
        eventType: 'coadmin-vaccine-selected',
        eventTimestamp: eventTimestamp,
        trigger: 'coadmin-vaccine-picker-navigation',
        location: this.selectedAppointment?.location,
      };
      this.eventService.setAnalyticsEvent(analyticsEvent);
      this.eventService.sendAnalyticsEvent();
    }

    const router = this.routeService.getRouter();
    router.navigate([path]);
  }

  public setItem(event: any) {
    this.services?.forEach((service) => {
      const selectedService = service.services.find((v) => v.serviceCode === event.code);
      if (selectedService) {
        const index = this.selectedServices.findIndex(
          (s) => s.serviceCode === selectedService.serviceCode
        );
        if (index === -1) {
          // If not already in selectedServices, add it
          this.selectedServices.push(selectedService);
        } else {
          // If already selected, remove it (toggle behavior)
          this.selectedServices.splice(index, 1);
        }
      }
    });
  }

  setOpenSections(openAllSections = false) {
    if (this.services) {
      // Open first section unless flag is set to true then open all
      this.isOpenSections = openAllSections
        ? this.services.map((_) => true)
        : this.services.map((_, index) => index === 0);
    }
  }

  setSelectedServices() {
    const { selectedAdditionalServices } = this.appointmentService;
    if (selectedAdditionalServices) {
      this.selectedServices = selectedAdditionalServices;
      // Open all the sections if we have a service error so the user can see their selections
      if (this.errors) {
        this.setOpenSections(true);
      }
    }
  }

  /**
   * Terms and Privacy Modal Handling
   */
  dismissTermsAndPrivacyModal() {
    this.setShowTermsAndPrivacyModal(false);
  }

  // Shows or Hides the Privacy Policy or Terms and Conditions Modal
  // true: modal displays
  // false: modal hides
  setShowTermsAndPrivacyModal(shouldShow: boolean) {
    this.showTermsAndPrivacyModal = shouldShow;
  }

  setModalType(type: ModalType) {
    this.modalType = type;
  }

  /**
   * Opens the terms and conditions URL the server provided
   */
  showTerms(event?: boolean) {
    if (event) {
      this.dismissTermsAndPrivacyModal();
    }

    setTimeout(() => {
      this.setModalType('prebooking-terms');
      this.setShowTermsAndPrivacyModal(true);
    }, 1);
  }

  showPrivacy(event?: boolean) {
    if (event) {
      this.dismissTermsAndPrivacyModal();
    }

    setTimeout(() => {
      this.setModalType('prebooking-privacy');
      this.setShowTermsAndPrivacyModal(true);
    }, 1);
  }
}
