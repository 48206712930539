import {
  Component,
  ElementRef,
  forwardRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { AppointmentService } from 'src/app/common/services/appointment/appointment.service';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Location } from 'src/app/common/model/location';
import { AppointmentHoldResponse, AppointmentSelection } from 'src/app/common/model/appointment';
import { Vaccine } from 'src/app/common/model/vaccine';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs';
import { LoadingLocationComponent } from 'src/app/components/loading-location/loading-location.component';
import { transition, trigger, useAnimation } from '@angular/animations';
import { FADE_IN_ANIMATION, FADE_OUT_ANIMATION } from 'src/app/common/constants/animations';
import { AlertPromptComponent } from 'src/app/components/alert-prompt/alert-prompt.component';
import { EventService } from 'src/app/common/services/event/event.service';
import {
  AnalyticsEventTriggerType,
  AnalyticsEventType,
} from 'src/app/common/types/track-event-type';
import { AlertPromptBase } from 'src/app/common/model/alert-prompt/alert-prompt-base';
import { AlertPromptExternalUrlService } from 'src/app/common/services/alert-prompt/alert-prompt-external-url.service';
import { AnalyticsEvent } from 'src/app/common/model/event';
import { DateTime } from 'luxon';
import { AlertPromptType } from 'src/app/common/types/alert-prompt-type';
import { AlertPromptDirections } from 'src/app/common/model/alert-prompt/alert-prompt-directions';
import { AlertPromptPharmacyWebsite } from 'src/app/common/model/alert-prompt/alert-prompt-pharmacy-website';
import { InertDirective } from 'src/app/directives/inert/inert.directive';
import { RouteService } from 'src/app/common/services/route/route.service';
import { LocationCardComponent } from 'src/app/components/location-card/location-card.component';
import { TypeaheadConfig, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { Address } from 'src/app/common/model/address';
import { TypeaheadConfigUtil } from 'src/app/common/utils/typeahead-config';
import { TermsAndPrivacyModalComponent } from 'src/app/components/terms-and-privacy-modal/terms-and-privacy-modal.component';
import { ModalType } from 'src/app/common/types/modal-type';
import { Title } from '@angular/platform-browser';
import { getEmbedded } from 'src/app/common/constants/general';
import { HeroComponent } from 'src/app/components/hero/hero.component';
import { VaccineEligibilityService } from 'src/app/common/services/vaccine-eligibility/vaccine-eligibility.service';
import { VaccineEligibilityQuizComponent } from 'src/app/components/vaccine-eligibility-quiz/vaccine-eligibility-quiz.component';
import { VaccineEligibilityToastComponent } from 'src/app/components/vaccine-eligibility-quiz/vaccine-eligibility-toast/vaccine-eligibility-toast.component';
import { NavigationEnd } from '@angular/router';
import { GoogleTagManagerService } from 'src/app/common/services/google-tag-manager/google-tag-manager.service';
import { TradeDeskPixelService } from 'src/app/common/services/trade-desk-pixel/trade-desk-pixel.service';
import { RemindMeButtonComponent } from 'src/app/components/remind-me-button/remind-me-button.component';

@Component({
  selector: 'app-appointment-search',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslatePipe,
    LocationCardComponent,
    LoadingLocationComponent,
    AlertPromptComponent,
    InertDirective,
    TypeaheadModule,
    forwardRef(() => TermsAndPrivacyModalComponent),
    HeroComponent,
    VaccineEligibilityQuizComponent,
    VaccineEligibilityToastComponent,
    RemindMeButtonComponent,
  ],
  providers: [
    {
      provide: TypeaheadConfig,
      useFactory: TypeaheadConfigUtil.getTypeaheadConfig,
    },
  ],
  templateUrl: './appointment-search.component.html',
  styleUrls: ['./appointment-search.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [useAnimation(FADE_IN_ANIMATION)]),
      transition(':leave', [useAnimation(FADE_OUT_ANIMATION)]),
    ]),
  ],
})
export class AppointmentSearchComponent implements OnInit, OnDestroy {
  @ViewChild('addressInput', { static: false }) addressInputRef!: ElementRef;
  @ViewChildren(LocationCardComponent)
  locationComponents?: QueryList<LocationCardComponent>;

  addressSuggestionsObj = new BehaviorSubject([]);
  addressSuggestions$: Observable<Address[]>;
  isAlertPromptVisible$: Observable<boolean>;
  isEligibilityQuizOpen$: Observable<boolean>;
  isEmbedded = false;
  loadingObj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading$: Observable<boolean>;
  set loading(isLoading: boolean) {
    this.loadingObj.next(isLoading);

    // don't allow user to change the zip while a search is already in place
    // this prevents value changing after its been set
    if (isLoading) {
      this.address.disable();
    } else {
      this.address.enable();
    }
  }

  geoLocationAccessDenied = false;
  localeSubscription: Subscription = new Subscription();
  locations: Array<Location> = [];
  routeSubscription: Subscription = new Subscription();
  selectedVaccine?: Vaccine;
  selectedVaccineName?: string;
  selectedVaccineDescription?: string;
  suppressSearchHeader = false;
  suppressVaccineEligibility = false;
  address = new FormControl('', [Validators.required]);
  addressOrCoordinatesEntered = false;
  addressHasFocus = false;
  showTermsAndPrivacyModal = false;
  suppressTermsAndPrivacy = false;
  modalType: ModalType = 'prebooking-privacy';

  get alertPrompt(): AlertPromptBase<any> | undefined {
    return this.alertPromptExternalUrlService.alertPrompt;
  }

  get isEligibilityToastOpen(): boolean {
    return this.vaccineEligibilityService.isEligibilityToastOpen;
  }

  private timeoutAddress: any;

  constructor(
    private environmentService: EnvironmentService,
    private appointmentService: AppointmentService,
    private vaccineEligibilityService: VaccineEligibilityService,
    private translateService: TranslateService,
    private alertPromptExternalUrlService: AlertPromptExternalUrlService,
    private routeService: RouteService,
    private eventService: EventService,
    private titleService: Title,
    private gtmService: GoogleTagManagerService,
    private ttdPixelService: TradeDeskPixelService
  ) {
    this.addressSuggestions$ = this.addressSuggestionsObj.asObservable();
    this.isAlertPromptVisible$ = this.alertPromptExternalUrlService.getAlertPromptVisibility();
    this.isEligibilityQuizOpen$ = this.vaccineEligibilityService.getIsEligibilityQuizOpen();
    this.isEmbedded = getEmbedded();
    this.loading$ = this.loadingObj.asObservable();

    // listen for locale changed so we can update the time UI with the proper locale format
    this.localeSubscription = this.environmentService.localeChanged.subscribe(() => {
      // retranslate vaccine instant translations if the locale changes
      this.setSelectedVaccineTranslations();

      this.locationComponents?.forEach((component: LocationCardComponent) => {
        component.reinitialize(); // Reinitialize each child component if locale changes
      });
    });

    if (!getEmbedded()) {
      const router = this.routeService.getRouter();
      this.routeSubscription = router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // reinit when same url is routed to
          this.ngOnInit();
        }
      });
    }
  }

  ngOnInit(): void {
    if (!getEmbedded()) {
      this.titleService.setTitle(this.translateService.instant('PAGES.PHARMACIES.TITLE'));
    }

    const { selectedZip, selectedVaccine, suppressSearchHeader, suppressTermsAndPrivacy } =
      this.environmentService;

    if (selectedVaccine) {
      this.selectedVaccine = selectedVaccine;
      this.setSelectedVaccineTranslations();
    }

    // if we have a selected zip at this point we need to fire off a search right away
    if (selectedZip) {
      this.address.setValue(selectedZip);
      this.searchByAddress();
    } else {
      this.focusAddressField();
    }

    if (suppressSearchHeader) {
      this.suppressSearchHeader = suppressSearchHeader;
    }

    if (suppressTermsAndPrivacy) {
      this.suppressTermsAndPrivacy = suppressTermsAndPrivacy;
    }

    this.suppressVaccineEligibility = this.vaccineEligibilityService.suppressVaccineEligibility;
  }

  ngOnDestroy(): void {
    this.localeSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  back() {
    // clear current state
    this.appointmentService.selectedAppointment = undefined;
    this.environmentService.selectedService = undefined;
    this.environmentService.selectedVaccine = undefined;

    const router = this.routeService.getRouter();
    router.navigate([this.routeService.getServicesRoute()]);
  }

  focusAddressField() {
    if (this.timeoutAddress) {
      clearInterval(this.timeoutAddress);
    }

    this.timeoutAddress = setTimeout(() => {
      this.addressInputRef.nativeElement.focus();
      this.addressHasFocus = true;
      this.timeoutAddress = null;
    }, 1);
  }

  /**
   * Allows you to getLocations by the users current coordinates.
   * This uses navigator and the browser will handle prompting user for location access.
   */
  public async searchByCurrentLocation() {
    this.loading = true;
    this.locations = [];
    this.address.reset();

    await this.appointmentService
      .getCurrentLocation()
      .then((position) => {
        this.geoLocationAccessDenied = false;
        this.addressOrCoordinatesEntered = true;
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;

        this.searchByCoordinates(lat, lng);
      })
      .catch((error) => {
        // Handle the error
        this.geoLocationAccessDenied = true;
        this.addressOrCoordinatesEntered = false;
        this.locations = [];
        this.loading = false;
      });
  }

  public searchTypeaheadAddress(event?: Event) {
    this.addressSuggestions$ = this.appointmentService
      .getAddresses(this.address.value ?? '')
      .pipe(debounceTime(500), distinctUntilChanged());
  }

  public searchByCoordinates(lat: number, lng: number) {
    this.loading = true;
    this.locations = [];

    this.appointmentService.getLocations(undefined, lat, lng).subscribe({
      next: (locations: Location[]) => {
        this.locations = locations;
        this.loading = false;
        this.addressOrCoordinatesEntered = true;
      },
      error: (error: any) => {
        // TODO: do something with this error?

        // reset state
        this.locations = [];
        this.loading = false;
      },
    });
  }

  public searchByAddress(event?: any) {
    // If zip entry is not valid we do not want to fire off a search
    if (!this.address.valid) {
      if (this.address.value?.length === 0) {
        this.focusAddressField();
      }

      return;
    }

    // Handle "Done" or "Enter" button
    if (event && event !== 'icon' && (event.key !== 'Enter' || event.keyCode !== 13)) {
      return;
    }

    // If address is valid set loading and location state
    this.addressOrCoordinatesEntered = true;
    this.loading = true;
    this.locations = [];

    const { address } = this;
    this.appointmentService.getLocations(address.value).subscribe({
      next: (locations: Location[]) => {
        this.environmentService.selectedZip = address.value as string; // preserve the zip they used to search
        this.locations = locations;
        this.loading = false;
      },
      error: (error: any) => {
        // reset state
        this.locations = [];
        this.loading = false;
        this.focusAddressField(); // set focus on the address field
      },
    });
  }

  /**
   * Updates the search input to be the selected typeahead result and fires off a search
   * @param item
   */
  selectTypeaheadAddress(event: any) {
    let searchTerm = '';
    if (event.item.name) {
      searchTerm += `${event.item.name} `;
    }
    if (event.item.address) {
      searchTerm += `${event.item.address}`;
    }

    this.address.setValue(searchTerm);
    this.searchByAddress();
  }

  /**
   * Highlights text returned from the typeahead that matches the search criteria
   * @param text
   * @returns
   */
  highlightMatchedTypeaheadText(text: string): string {
    // Use a regular expression \s+ as the delimiter, which matches one or more whitespace characters (including spaces) and commas.
    // Then, use the filter method to remove any empty strings from the resulting array.
    // This way, we receive an array containing only the words, and any leading or trailing spaces will be ignored.
    const searchTerms = this.address.value?.split(/[\s,]+/).filter((word) => word !== '');

    let highlightedText = text;
    searchTerms?.forEach((term) => {
      // use word boundaries - escaping special characters in the search term and ensure they are matched correctly
      const regex = new RegExp(`\\b(${term.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')})\\b`, 'gi');
      highlightedText = highlightedText.replace(regex, '<span class="text-bold">$1</span>');
    });
    return highlightedText;
  }

  private setSelectedVaccineTranslations() {
    const { selectedVaccine } = this;
    if (selectedVaccine) {
      this.selectedVaccineName = this.translateService.instant(selectedVaccine?.vaccineNameTC);
      this.selectedVaccineDescription = this.translateService.instant(
        selectedVaccine?.vaccineDescriptionTC
      );
    }
  }

  public doSelectAppointment(event: AppointmentSelection | undefined) {
    /**
     * If supportsApiScheduling is false for the location, the url to which the user
     * should be redirected in order to schedule this appointment slot (if api scheduling is not supported)
     */
    if (event?.supportsApiScheduling === false) {
      const externalBookingUrl =
        event?.appointmentBookingUrl !== ''
          ? event?.appointmentBookingUrl
          : event?.locationBookingUrl;

      // prompt user asking if they want to navigate away
      if (externalBookingUrl) {
        this.gtmService.trackFloodlightEvent('DC-15065329/evax000/timeclik+unique');
        this.ttdPixelService.trackEvent('sxr1ong');

        this.setAnalyticsEvent('website-transfer', 'appointment-booking-url', event.location);
        this.setAlertPrompt('pharmacy-website');
        this.openExternalUrl(externalBookingUrl);
      }
      return;
    }

    /**
     * Supports backbone API scheduling
     */
    // Set the appointment for our API scheduling
    this.appointmentService.selectedLocation = event?.location;
    this.appointmentService.selectedAppointment = event;

    this.gtmService.trackFloodlightEvent('DC-15065329/evax000/timeclik+unique');
    this.ttdPixelService.trackEvent('sxr1ong');

    const router = this.routeService.getRouter();
    // Determine if we're in the retry state
    // If the retailer is the same (locationGroupId) we can bypass the reservation form and reuse the existing one
    // If the retailer is not the same we need to route to the coadmin or reservation form
    if (this.appointmentService.isInRetryState() && event) {
      const { reservation, isRetryForLocationGroupId } = this.appointmentService;
      // if it's the same location group and the additionalData exists we can bypass the form
      if (isRetryForLocationGroupId === event.location.locationGroupId) {
        if (reservation && reservation.additionalData) {
          // hold the new appointment time
          this.loading = true;

          this.appointmentService.holdAppointment()?.subscribe({
            next: (response: AppointmentHoldResponse) => {
              // update existing reservation
              this.appointmentService.updateExistingReservation(event, response);

              // set loading
              this.loading = false;

              // route to the review and confirm screen
              router.navigate([this.routeService.getReviewRoute()]);
            },
            error: (error: any) => {
              // Set error
              this.appointmentService.setRetryState(event.location.locationGroupId); // set that we're in retryState

              // set loading
              this.loading = false;

              const router = this.routeService.getRouter();
              router.navigate([this.routeService.getReservationErrorRoute()]);
            },
          });
        } else {
          // Route to reservation form since user should have already picked their additional vaccines
          const path = this.routeService.getReservationRoute();

          // Route to screen
          router.navigate([path]);
        }
      } else {
        // Not the same location group so we can't bypass the form

        // If coadmin supported route to coadmin otherwise route to reservation form
        const path = event?.location.coadminEnabled
          ? this.routeService.getCoadminRoute()
          : this.routeService.getReservationRoute();

        // Route to screen
        router.navigate([path]);
      }
    } else {
      // If coadmin supported route to coadmin otherwise route to reservation form
      const path = event?.location.coadminEnabled
        ? this.routeService.getCoadminRoute()
        : this.routeService.getReservationRoute();
      router.navigate([path]);
    }
  }

  /**
   * This handles encoding the location and routing to the LocationDetail component
   */
  public doViewLocationDetail(event: Location) {
    this.appointmentService.selectedLocation = event;

    const router = this.routeService.getRouter();
    router.navigate([this.routeService.getLocationDetailsRoute(event.locationId)]);
  }

  /**
   * Analytics Event Handling
   */
  setAnalyticsEvent(
    eventType: AnalyticsEventType,
    trigger: AnalyticsEventTriggerType,
    location: Location
  ) {
    const eventTimestamp = DateTime.local().toUTC().toString();
    const analyticsEvent: AnalyticsEvent = {
      eventType: eventType,
      eventTimestamp: eventTimestamp,
      trigger: trigger,
      location: location,
    };

    this.eventService.setAnalyticsEvent(analyticsEvent);
  }

  sendAnalyticsEvent() {
    this.eventService.sendAnalyticsEvent();
  }

  /**
   * Alert Prompt Handling
   */
  public alertPromptCancel() {
    this.hideAlertPrompt();
  }

  public alertPromptContinue() {
    this.alertPromptExternalUrlService.continueToExternalUrl();

    this.eventService.sendAnalyticsEvent();

    this.hideAlertPrompt();
  }

  public openExternalUrl(event: string) {
    this.alertPromptExternalUrlService.openAlertPrompt(event);
  }

  private hideAlertPrompt() {
    this.alertPromptExternalUrlService.dismissAlertPrompt();

    this.eventService.clearAnalyticsEvent();
  }

  public setAlertPrompt(type: AlertPromptType) {
    let prompt: AlertPromptBase<any> | undefined;
    switch (type) {
      case 'directions':
        prompt = new AlertPromptDirections();
        break;
      case 'pharmacy-website':
        prompt = new AlertPromptPharmacyWebsite();
        break;
      default:
        break;
    }
    if (prompt) {
      this.alertPromptExternalUrlService.setAlertPrompt(prompt);
    }
  }

  /**
   * Terms and Privacy Modal Handling
   */
  dismissTermsAndPrivacyModal() {
    this.setShowTermsAndPrivacyModal(false);
  }

  // Shows or Hides the Privacy Policy or Terms and Conditions Modal
  // true: modal displays
  // false: modal hides
  setShowTermsAndPrivacyModal(shouldShow: boolean) {
    this.showTermsAndPrivacyModal = shouldShow;
  }

  setModalType(type: ModalType) {
    this.modalType = type;
  }

  /**
   * Opens the terms and conditions URL the server provided
   */
  showTerms(event?: boolean) {
    if (event) {
      this.dismissTermsAndPrivacyModal();
    }

    setTimeout(() => {
      this.setModalType('prebooking-terms');
      this.setShowTermsAndPrivacyModal(true);
    }, 1);
  }

  showPrivacy(event?: boolean) {
    if (event) {
      this.dismissTermsAndPrivacyModal();
    }

    setTimeout(() => {
      this.setModalType('prebooking-privacy');
      this.setShowTermsAndPrivacyModal(true);
    }, 1);
  }
}
