import { Component, forwardRef, OnInit } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ModalType } from '../common/types/modal-type';
import { getEmbedded } from '../common/constants/general';
import { Title } from '@angular/platform-browser';
import { EnvironmentService } from '../common/services/environment/environment.service';
import { CommonModule } from '@angular/common';
import { InertDirective } from '../directives/inert/inert.directive';
import { TermsAndPrivacyModalComponent } from '../components/terms-and-privacy-modal/terms-and-privacy-modal.component';
import { transition, trigger, useAnimation } from '@angular/animations';
import { FADE_IN_ANIMATION, FADE_OUT_ANIMATION } from '../common/constants/animations';
import { BehaviorSubject, Observable } from 'rxjs';
import { AbandonmentService } from '../common/services/abandonment/abandonment.service';

@Component({
  selector: 'app-unsubscribe',
  imports: [
    CommonModule,
    TranslatePipe,
    InertDirective,
    forwardRef(() => TermsAndPrivacyModalComponent),
  ],
  templateUrl: './unsubscribe.component.html',
  styleUrl: './unsubscribe.component.scss',
  animations: [
    trigger('fadeIn', [
      transition(':enter', [useAnimation(FADE_IN_ANIMATION)]),
      transition(':leave', [useAnimation(FADE_OUT_ANIMATION)]),
    ]),
  ],
})
export class UnsubscribeComponent implements OnInit {
  loadingObj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading$: Observable<boolean>;
  showTermsAndPrivacyModal = false;
  suppressTermsAndPrivacy = false;
  modalType: ModalType = 'prebooking-privacy';
  didFail = false;

  constructor(
    private environmentService: EnvironmentService,
    private titleService: Title,
    private translateService: TranslateService,
    private abandonmentService: AbandonmentService
  ) {
    this.loading$ = this.loadingObj.asObservable();
  }

  set loading(isLoading: boolean) {
    this.loadingObj.next(isLoading);
  }

  ngOnInit() {
    if (!getEmbedded()) {
      this.titleService.setTitle(
        this.translateService.instant('PAGES.REMINDERS_UNSUBSCRIBE_CONFIRMATION.TITLE')
      );
    }
    this.suppressTermsAndPrivacy = this.environmentService.suppressTermsAndPrivacy;
    this.handleUnsubscribe();
  }

  handleUnsubscribe() {
    if (!this.abandonmentService.unsubscribeParam) {
      return;
    }

    this.loading = true;

    this.abandonmentService.getUnsubscribe().subscribe({
      next: () => {
        this.loading = false;
        this.didFail = false;
      },
      error: (error: any) => {
        this.loading = false;
        this.didFail = true;
      },
    });
  }

  /**
   * Terms and Privacy Modal Handling
   */
  dismissTermsAndPrivacyModal() {
    this.setShowTermsAndPrivacyModal(false);
  }

  // Shows or Hides the Privacy Policy or Terms and Conditions Modal
  // true: modal displays
  // false: modal hides
  setShowTermsAndPrivacyModal(shouldShow: boolean) {
    this.showTermsAndPrivacyModal = shouldShow;
  }

  setModalType(type: ModalType) {
    this.modalType = type;
  }

  /**
   * Opens the terms and conditions URL the server provided
   */
  showTerms(event?: boolean) {
    if (event) {
      this.dismissTermsAndPrivacyModal();
    }

    setTimeout(() => {
      this.setModalType('prebooking-terms');
      this.setShowTermsAndPrivacyModal(true);
    }, 1);
  }

  showPrivacy(event?: boolean) {
    if (event) {
      this.dismissTermsAndPrivacyModal();
    }

    setTimeout(() => {
      this.setModalType('prebooking-privacy');
      this.setShowTermsAndPrivacyModal(true);
    }, 1);
  }
}
