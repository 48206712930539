import { HttpEvent, HttpRequest, HttpErrorResponse, HttpHandlerFn } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

export function asteriskInterceptor(
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> {
  // Check if asterisk is present in localStorage for non-Prod environments
  if (!environment.production) {
    const apiErrorKey = environment.apiLocalStorageErrorKey;
    const errorContent = localStorage.getItem(apiErrorKey);

    if (errorContent?.includes('*')) {
      return throwError(() => new Error('Asterisk (*) detected in localStorage'));
    } else if (errorContent?.includes('500')) {
      return throwError(
        () =>
          new HttpErrorResponse({
            status: 500,
            statusText: 'Internal Server Error',
            url: req.url,
            error: { message: 'Asterisk (*) detected in localStorage - Simulated 500 Error' },
          })
      );
    }
  }

  // Pass the request to the next interceptor or the final handler
  return next(req);
}
