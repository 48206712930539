import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { PhoneMaskDirective } from 'src/app/directives/phone-mask/phone-mask.directive';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { getEmbedded, getEmbeddedAssetsUrl } from 'src/app/common/constants/general';
import { AbandonmentService } from 'src/app/common/services/abandonment/abandonment.service';
import { RecaptchaService } from 'src/app/common/services/recaptcha/recaptcha.service';
import { VaxErrorDetail } from 'src/app/common/model/error';

@Component({
  selector: 'app-alert-prompt-reminder',
  imports: [CommonModule, TranslatePipe, ReactiveFormsModule, PhoneMaskDirective],
  templateUrl: './alert-prompt-reminder.component.html',
  styleUrls: ['./alert-prompt-reminder.component.scss'],
})
export class AlertPromptReminderComponent implements OnInit, OnDestroy {
  form: FormGroup;
  errorIconUrl = 'assets/icons/error.svg';

  constructor(
    private abandonmentService: AbandonmentService,
    private recaptchaService: RecaptchaService
  ) {
    if (getEmbedded()) {
      this.errorIconUrl = `${getEmbeddedAssetsUrl()}/assets/icons/error.svg`;
    }

    this.form = this.abandonmentService.form;
  }

  ngOnInit(): void {
    this.abandonmentService.updateValidators();
  }

  ngOnDestroy(): void {
    this.abandonmentService.form.reset();
    this.recaptchaService.clearError();
    this.abandonmentService.clearErrors();
  }

  get errors(): VaxErrorDetail[] | undefined {
    const { errors } = this.abandonmentService;
    return errors;
  }

  get isEmail() {
    return this.abandonmentService.isEmail;
  }

  get isEmailValid() {
    return this.form.get('email')?.valid;
  }

  get isEmailTouched() {
    return this.form.get('email')?.touched;
  }

  get isPhoneValid() {
    return this.form.get('phone')?.valid;
  }

  get isPhoneTouched() {
    return this.form.get('phone')?.touched;
  }

  get recaptchaError() {
    const err = <any>this.recaptchaService.recaptchaError;

    if (!err) {
      return '';
    }

    if (err.details && err.details.length > 0) {
      return err.details[0].reason;
    }

    return '';
  }

  toggleEmail() {
    this.abandonmentService.toggleEmail();
  }

  textboxValueChanged(event: any) {
    this.form.updateValueAndValidity();
  }
}
