import {
  ApplicationConfig,
  importProvidersFrom,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { provideRouter, withRouterConfig } from '@angular/router';

import { ROUTES } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MissingTranslationHandler, provideTranslateService } from '@ngx-translate/core';
import { VaxMissingTranslationHandler } from './common/services/translation/vax-missing-translation-loader';
import { AppInitService } from './app-init.service';
import { EnvironmentGuard } from './common/guards/environment.guard';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { requestInterceptor } from './common/interceptors/request.interceptor';
import { asteriskInterceptor } from './common/interceptors/asterisk.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    AppInitService,
    EnvironmentGuard,
    provideAppInitializer(() => {
      const initializerFn = (
        (appInitService: AppInitService) => async () =>
          await appInitService
            .init()
            .catch((error) => console.log(error))
            .then(() => appInitService.configureUrl())
      )(inject(AppInitService));
      return initializerFn();
    }),
    provideRouter(
      ROUTES,
      withRouterConfig({
        onSameUrlNavigation: 'reload', // Enable reloading for the same URL
      })
    ),
    importProvidersFrom(BsDatepickerModule.forRoot()),
    importProvidersFrom(AccordionModule.forRoot()),
    importProvidersFrom(AlertModule.forRoot()),
    importProvidersFrom(CollapseModule.forRoot()),
    importProvidersFrom(BrowserAnimationsModule),
    provideTranslateService({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: VaxMissingTranslationHandler, // EasyVax custom missing translation handler
      },
    }),
    importProvidersFrom(TypeaheadModule.forRoot()),
    provideHttpClient(withInterceptors([requestInterceptor, asteriskInterceptor])),
  ],
};
