import { CommonModule, DOCUMENT } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { LanguagePickerComponent } from './components/language-picker/language-picker.component';
import { Observable, Subscription } from 'rxjs';
import { EnvironmentService } from './common/services/environment/environment.service';
import { LanguagePickerButtonComponent } from './components/language-picker/language-picker-button/language-picker-button.component';
import { getEmbedded, getEmbeddedAssetsUrl } from './common/constants/general';
import { LogoComponent } from './components/logo/logo.component';
import { environment } from 'src/environments/environment';
import { AbandonmentService } from './common/services/abandonment/abandonment.service';
import { AlertPromptBase } from './common/model/alert-prompt/alert-prompt-base';
import { AlertPromptAbandonmentService } from './common/services/alert-prompt/alert-prompt-abandonment.service';
import { AlertPromptComponent } from './components/alert-prompt/alert-prompt.component';
import { AlertPromptIdle } from './common/model/alert-prompt/alert-prompt-idle';
import { AlertPromptReminder } from './common/model/alert-prompt/alert-prompt-reminder';
import { AlertPromptReminderSuccess } from './common/model/alert-prompt/alert-prompt-reminder-success';

@Component({
  selector: 'app-root',
  imports: [
    CommonModule,
    RouterModule,
    LanguagePickerComponent,
    LanguagePickerButtonComponent,
    LogoComponent,
    AlertPromptComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  styleUrls: ['../styles.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy, OnInit {
  isAlertPromptVisible$: Observable<boolean>;
  languagePickerButtonEnabled = false; // whether or not the current screen should have the language picker
  shouldShowLanguagePicker$: Observable<boolean>;
  shouldShowLanguagePickerButton = false;
  suppressSearchHeader = false;
  subscriptions = new Subscription();
  easyVaxLogoUrl = 'assets/easyvax-logo.png';

  constructor(
    public abandonmentService: AbandonmentService,
    private environmentService: EnvironmentService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private alertPromptAbandonmentService: AlertPromptAbandonmentService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.isAlertPromptVisible$ = this.alertPromptAbandonmentService.getAlertPromptVisibility();
    this.shouldShowLanguagePicker$ = this.environmentService.shouldShowLanguagePicker$;
    this.shouldShowLanguagePickerButton = this.environmentService.shouldShowLanguagePickerButton;
    this.suppressSearchHeader = this.environmentService.suppressSearchHeader;

    const routeSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkRouteForLanguagePickerEnabled();
      }
    });

    this.subscriptions.add(routeSub);
  }

  get alertPrompt(): AlertPromptBase<any> | undefined {
    return this.alertPromptAbandonmentService.alertPrompt;
  }

  ngOnInit(): void {
    this.checkRouteForLanguagePickerEnabled();

    if (getEmbedded()) {
      this.easyVaxLogoUrl = `${getEmbeddedAssetsUrl()}/assets/easyvax-logo.png`;
    } else {
      // Init for main app, embed inits in easyvax-embed.component.ts
      this.initializeGtag();
      this.initializeTradeDeskPixel();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // Check the activated route to determine visibility of the language picker button
  private checkRouteForLanguagePickerEnabled() {
    this.languagePickerButtonEnabled = false;

    if (this.route.firstChild) {
      const routeData = this.route.firstChild.snapshot.data;
      this.languagePickerButtonEnabled = routeData['languagePickerButtonEnabled'] ?? false; // Use route data to control visibility
    }
  }

  /**
   * Alert Prompt Handling
   */
  public alertPromptCancel() {
    if (this.alertPromptAbandonmentService.alertPrompt instanceof AlertPromptIdle) {
      this.hideAlertPrompt();
      setTimeout(() => {
        this.abandonmentService.setAndShowAlertPrompt('abandonment-reminder');
      });
    } else if (this.alertPromptAbandonmentService.alertPrompt instanceof AlertPromptReminder) {
      this.hideAlertPrompt();
    }
  }

  public alertPromptContinue() {
    const { alertPrompt } = this.alertPromptAbandonmentService;
    if (alertPrompt instanceof AlertPromptIdle) {
      this.hideAlertPrompt();
    } else if (alertPrompt instanceof AlertPromptReminder) {
      this.abandonmentService.submitReminder();
    } else if (alertPrompt instanceof AlertPromptReminderSuccess) {
      this.hideAlertPrompt();
    }
  }

  private hideAlertPrompt() {
    this.alertPromptAbandonmentService.dismissAlertPrompt();
  }

  // Wrapper for gtag calls
  public gtag(...args: any[]): void {
    if (typeof window !== 'undefined' && (window as any).gtag) {
      (window as any).gtag(...args);
    }
  }

  private initializeGtag(): void {
    const { gtagId, gFloodlightId } = environment;

    if (!gtagId || !gFloodlightId) {
      return;
    }

    const gtagUrl = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
    this.injectScript(gtagUrl, () => {
      // Initialize dataLayer and gtag after the script is loaded
      (window as any).dataLayer = (window as any).dataLayer || [];

      (window as any).gtag = function () {
        // eslint-disable-next-line prefer-rest-params
        (window as any).dataLayer.push(arguments);
      };

      // Initialize gtag
      this.gtag('js', new Date());
      this.gtag('config', gtagId); // Google Analytics
      this.gtag('config', gFloodlightId); // Floodlight tracking
    });
  }

  private initializeTradeDeskPixel(): void {
    if (!environment.tradeDeskPixelIsSupported) {
      return;
    }

    const tradeDeskPixelUrl = environment.tradeDeskPixelUrl;

    this.injectScript(tradeDeskPixelUrl, () => {
      // This callback is triggered when the script is loaded
      this.initializeUniversalPixel();
    });
  }

  private initializeUniversalPixel(): void {
    if (typeof window['TTDUniversalPixelApi'] === 'function') {
      const universalPixelApi = new window['TTDUniversalPixelApi']();
      universalPixelApi.init('bfo0cyt', ['p5a2s4y'], environment.tradeDeskUniversalPixelUrl, {
        nocache: new Date().getTime(),
      });
    } else {
      console.error('TTDUniversalPixelApi is not available');
    }
  }

  private injectScript(scriptUrl: string, onLoadCallback?: () => void): void {
    const existingScript = this.document.head.querySelector(`script[src="${scriptUrl}"]`);
    if (!existingScript) {
      const script = this.renderer.createElement('script');
      script.src = scriptUrl;
      script.async = true;
      script.defer = true;
      if (onLoadCallback) {
        script.onload = onLoadCallback;
      }
      this.renderer.appendChild(this.document.head, script);
    } else if (onLoadCallback) {
      onLoadCallback(); // Execute callback if script already exists
    }
  }
}
