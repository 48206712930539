import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { trigger, state, transition, style, animate } from '@angular/animations';
import {
  VaccineEligibilityQuizQuestionOption,
  VaccineEligibilityQuizSection,
  VaccineEligibilityRequest,
} from 'src/app/common/model/vaccine-eligibility';
import {
  SECTIONS,
  OPTION_NONE_VALUE,
  PREGNANT_SECTION_API_VALUES,
  AGE_SECTION_API_VALUES,
  PREGNANT_SECTION_API_KEY,
} from './vaccine-eligibility-questions.constants';
import { VaccineEligibilityService } from 'src/app/common/services/vaccine-eligibility/vaccine-eligibility.service';
import {
  getEmbeddedAssetsUrl,
  VaccineEligibilityQuestionType,
} from 'src/app/common/constants/general';
import { LoadingComponent } from 'src/app/components/loading/loading.component';
import { VaccineEligibilityDisclaimerCardComponent } from '../vaccine-eligibility-disclaimer-card/vaccine-eligibility-disclaimer-card.component';
import { AnalyticsEvent } from 'src/app/common/model/event';
import { DateTime } from 'luxon';
import { EventService } from 'src/app/common/services/event/event.service';
import { AnalyticsEventTriggerType } from 'src/app/common/types/track-event-type';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-vaccine-eligibility-questions',
    imports: [
        CommonModule,
        FormsModule,
        AccordionModule,
        TranslatePipe,
        LoadingComponent,
        VaccineEligibilityDisclaimerCardComponent,
    ],
    templateUrl: './vaccine-eligibility-questions.component.html',
    styleUrls: ['./vaccine-eligibility-questions.component.scss'],
    animations: [
        trigger('rotate', [
            state('up', style({ transform: 'rotate(0deg)' })),
            state('down', style({ transform: 'rotate(180deg)' })),
            transition('down <=> up', animate('200ms ease-in-out')),
        ]),
    ]
})
export class VaccineEligibilityQuestionsComponent implements OnInit {
  @Input() isSubmitting: boolean | null = false;
  @Output() handleSubmit: EventEmitter<Record<string, any>> = new EventEmitter();

  sections: VaccineEligibilityQuizSection[] = SECTIONS;

  vaccinationArmSmallUrl = 'assets/vaccination-arm-sm.jpg';
  vaccinationArmLargeUrl = 'assets/vaccination-arm.jpg';

  vaccineEligibilityQuestionType = VaccineEligibilityQuestionType;

  constructor(
    private translateService: TranslateService,
    private vaccineEligibilityService: VaccineEligibilityService,
    private eventService: EventService,
    private sanitizer: DomSanitizer
  ) {}

  get isFormValid() {
    const previousVaccinations = this.sections[2];
    if (previousVaccinations && previousVaccinations.question.answer) {
      return previousVaccinations.question.answer.length > 0;
    }
    return false;
  }

  get isPreviousVaccinationsVisible() {
    const previousVaccinations = this.sections[2];
    return previousVaccinations.isVisible === true;
  }

  ngOnInit(): void {
    this.checkVisibilityConditions(); // Dynamically update visibility based on conditions

    this.vaccinationArmSmallUrl = `${getEmbeddedAssetsUrl()}/assets/vaccination-arm-sm.jpg`;
    this.vaccinationArmLargeUrl = `${getEmbeddedAssetsUrl()}/assets/vaccination-arm.jpg`;
  }

  restartQuiz() {
    this.sections.forEach((section, index) => {
      // Reset answers
      section.question.answer =
        section.question.type === VaccineEligibilityQuestionType.MULTIPLE ? [] : null;

      // Reset the checked state for multiple options
      if (
        section.question.type === VaccineEligibilityQuestionType.MULTIPLE &&
        section.question.options
      ) {
        section.question.options.forEach((option) => {
          option.checked = false;
        });
      }

      // Reset visibility and state
      section.isVisible = index === 0; // Show only the first section
      section.isOpen = index === 0; // Open only the first section
    });

    // Analytics event
    const eventTimestamp = DateTime.local().toUTC().toString();
    const analyticsEvent: AnalyticsEvent = {
      eventType: 'get-eligibility',
      eventTimestamp: eventTimestamp,
      trigger: 'eligibility-restarted',
    };
    this.eventService.setAnalyticsEvent(analyticsEvent);
    this.eventService.sendAnalyticsEvent();
  }

  toggleIsOpen() {
    this.vaccineEligibilityService.toggleElibilityQuizIsOpen();
  }

  // Get the display text for the selected answer(s)
  getAnswerDisplayText(section: VaccineEligibilityQuizSection): string {
    if (Array.isArray(section.question.answer)) {
      // For multiple choice, join the displayText of selected options
      const selectedOptions = section.question.options
        .filter((option) => section.question.answer?.includes(option.value))
        .map((option) => this.translateService.instant(option.displayTC));
      return selectedOptions.join(', ');
    }

    // For single choice, return the corresponding displayText
    const selectedOption = section.question.options.find(
      (option) => option.value === section.question.answer
    );
    return selectedOption ? this.translateService.instant(selectedOption.displayTC) : '';
  }

  getErrorDisplayText(answer: string | string[]): SafeHtml {
    if (Array.isArray(answer)) {
      // For multiple choice, join the displayText of selected options
      const selectedOptions = this.sections
        .map((section) => section.question.options)
        .flat()
        .filter((option) => answer.includes(option.value) && option.errorTC)
        .map((option) => (option.errorTC ? this.translateService.instant(option.errorTC) : ''));
      return selectedOptions.join(', ') || '';
    } else {
      // For single choice, return the corresponding displayText
      const selectedOption = this.sections
        .map((section) => section.question.options)
        .flat()
        .find((option) => option.value === answer);
      return selectedOption?.errorTC
        ? this.sanitizer.bypassSecurityTrustHtml(
            this.translateService.instant(selectedOption.errorTC)
          )
        : '';
    }
  }

  toggleSection(section: any): void {
    section.isOpen = !section.isOpen;
  }

  onCheckboxChange(event: Event, index: number): void {
    const section = this.sections[index];
    const checkbox = event.target as HTMLInputElement;

    if (!Array.isArray(section.question.answer)) {
      section.question.answer = [];
    }

    if (!checkbox.checked) {
      section.question.answer = section.question.answer.filter((value) => value !== checkbox.value);
      return;
    }

    if (checkbox.value === OPTION_NONE_VALUE) {
      section.question.options = section.question.options.map((option) =>
        option.value === OPTION_NONE_VALUE
          ? option
          : {
              ...option,
              checked: false,
            }
      );
      section.question.answer = [checkbox.value];
    } else {
      section.question.options = section.question.options.map((option) =>
        option.value === OPTION_NONE_VALUE
          ? {
              ...option,
              checked: false,
            }
          : option
      );
      section.question.answer = section.question.answer.filter(
        (value) => value !== OPTION_NONE_VALUE
      );
      section.question.answer.push(checkbox.value);
    }
  }

  onSelectionChange(index: number): void {
    this.advanceToNextSection(index);

    this.sendAnalyticsEvent(index);
  }

  getSelectionOptions(
    section: VaccineEligibilityQuizSection
  ): VaccineEligibilityQuizQuestionOption[] {
    if (!section.question.optionsSorted) {
      return section.question.options;
    }
    return section.question.options.sort((optionA, optionB) => {
      if (
        this.translateService.instant(optionA.displayTC) <
        this.translateService.instant(optionB.displayTC)
      ) {
        return optionA.stickToBottom ? 1 : -1;
      }
      if (
        this.translateService.instant(optionA.displayTC) >
        this.translateService.instant(optionB.displayTC)
      ) {
        return optionB.stickToBottom ? -1 : 1;
      }
      return 0;
    });
  }

  advanceToNextSection(currentIndex: number): void {
    // Check if there is a next section
    let nextIndex = currentIndex + 1;

    // Loop to find the next visible section
    while (nextIndex < this.sections.length && !this.sections[nextIndex].isVisible) {
      nextIndex++;
    }

    if (nextIndex < this.sections.length) {
      // Set the next section to be open and the current one to closed
      this.sections[currentIndex].isOpen = false;
      this.sections[nextIndex].isOpen = true;
    }
  }

  sendAnalyticsEvent(index?: number) {
    const payload: Partial<VaccineEligibilityRequest> = this.sections
      .filter((section) => section.question.answer !== null && section.question.answer.length > 0) // Ensure only sections with answers
      .reduce((acc, section) => {
        acc[section.question.apiKey] = section.question.answer;
        return acc;
      }, {} as Record<string, any>);

    // Analytics event
    const eventTimestamp = DateTime.local().toUTC().toString();
    let eventTriggerType: AnalyticsEventTriggerType = 'eligibility-age';
    if (index === 0) {
      eventTriggerType = 'eligibility-age';
    } else if (index === 1) {
      eventTriggerType = 'eligibility-pregnant';
    } else {
      eventTriggerType = 'eligibility-previous-vaccinations';
    }

    const analyticsEvent: AnalyticsEvent = {
      eventType: 'get-eligibility',
      eventTimestamp: eventTimestamp,
      trigger: eventTriggerType,
      ...payload,
    };
    this.eventService.setAnalyticsEvent(analyticsEvent);
    this.eventService.sendAnalyticsEvent();
  }

  submitForm(): void {
    const payload: Partial<VaccineEligibilityRequest> = this.sections
      .filter((section) => section.isVisible && section.question.answer !== null) // Ensure only visible sections with answers
      .reduce((acc, section) => {
        acc[section.question.apiKey] = section.question.answer;
        return acc;
      }, {} as Record<string, any>);

    // Default to PREGNANT_NO if null
    const pregnancySection = this.sections.find(
      (section) => section.question.apiKey === PREGNANT_SECTION_API_KEY
    );
    if (!pregnancySection || pregnancySection.question.answer === null) {
      payload[PREGNANT_SECTION_API_KEY] = PREGNANT_SECTION_API_VALUES.PREGNANT_NO;
    }

    // Send analytics event so the final previous vaccinations event is tracked when tapping "Check Eligibility"
    this.sendAnalyticsEvent();

    this.handleSubmit.emit(payload);
  }

  checkVisibilityConditions(): void {
    const [age, pregnant, previousVaccinations] = this.sections;

    // Handle above 60 age condition: Clears pregnancy question and answer
    if (
      age.question.answer === AGE_SECTION_API_VALUES.AGE_60_64 ||
      age.question.answer === AGE_SECTION_API_VALUES.AGE_65_PLUS
    ) {
      this.hideSection(pregnant);
      this.clearAnswer(pregnant);
      this.showSection(previousVaccinations);
    }

    // Clear and hide non-age sections for under 18 age group
    if (age.question.answer === AGE_SECTION_API_VALUES.AGE_0_17) {
      this.hideSection(pregnant);
      this.clearAnswer(pregnant);
      this.hideSection(previousVaccinations);
      this.clearAnswer(previousVaccinations);
    }

    // Show pregnant section based on age
    if (
      age.question.answer &&
      age.question.answer !== AGE_SECTION_API_VALUES.AGE_0_17 &&
      age.question.answer !== AGE_SECTION_API_VALUES.AGE_60_64 &&
      age.question.answer !== AGE_SECTION_API_VALUES.AGE_65_PLUS
    ) {
      this.showSection(pregnant);
    }

    // Show previousVaccinations if pregnant question is answered
    if (pregnant.question.answer) {
      this.showSection(previousVaccinations);
    }
  }

  public hideSection(section: any): void {
    section.isVisible = false;
  }

  public showSection(section: any): void {
    section.isVisible = true;
  }

  public clearAnswer(section: any): void {
    section.question.answer = null;

    if (
      section.question.type === VaccineEligibilityQuestionType.MULTIPLE &&
      section.question.options
    ) {
      section.question.options.forEach((option: VaccineEligibilityQuizQuestionOption) => {
        option.checked = false;
      });
    }
  }
}
