import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { DateUtil } from 'src/app/common/utils/date';
import { LocationIntegrationLevelBaseComponent } from '../location-integration-level-base/location-integration-level-base.component';

@Component({
    selector: 'app-location-integration-level-twohalf',
    imports: [CommonModule, LocationIntegrationLevelBaseComponent, TranslatePipe],
    templateUrl: './location-integration-level-twohalf.component.html',
    styleUrls: ['./location-integration-level-twohalf.component.scss']
})
export class LocationIntegrationLevelTwohalfComponent extends LocationIntegrationLevelBaseComponent {
  numAppointments = 0; // total number of appointment times

  // Returns the day of the week for the date supplied eg: Thursday
  getAppointmentDayOfWeek(date: string): string {
    return DateUtil.formatDayOfWeek(date);
  }

  // Called if locale changes so times are in the proper locale
  reinitialize() {
    this.setAppointmentTimes();
  }

  public override setState() {
    this.numAppointments = this.filteredAppointments.length;
  }
}
