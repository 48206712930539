import { Location } from './location';
import { Question } from './question/question';

export interface Appointment {
  date: string;
  times: Array<AppointmentTime>;
}

export interface AppointmentTime {
  appointmentId: string;
  time: string;
  bookingUrl?: string;
  duration: number;
}

export interface AppointmentTimesResponse {
  appointments: Appointment[];
}

export class AppointmentDateTime implements AppointmentTime {
  appointmentId: string;
  date: string;
  time: string;
  prettyDate: string;
  prettyTime: string;
  isToday: boolean;
  bookingUrl: string;
  duration: number;

  constructor(
    appointmentId: string,
    date: string,
    time: string,
    prettyDate: string,
    prettyTime: string,
    isToday: boolean,
    bookingUrl: string,
    duration: number
  ) {
    this.appointmentId = appointmentId;
    this.date = date;
    this.time = time;
    this.prettyDate = prettyDate;
    this.prettyTime = prettyTime;
    this.isToday = isToday;
    this.bookingUrl = bookingUrl;
    this.duration = duration;
  }
}

export interface AppointmentSelection {
  location: Location;
  locationId: string;
  appointmentId: string;
  date: string;
  time: string;
  duration: number;
  supportsApiScheduling: boolean;
  appointmentBookingUrl?: string;
  locationBookingUrl?: string;
}

export interface AppointmentReservation {
  campaignId?: string;
  locationId?: string;
  locationGroupId?: string;
  date?: string;
  duration: number;
  vaccineCode?: string;
  appointmentId?: string;
  holdId?: string;
  additionalData?: any;
  additionalVaccineCodes?: string[];
  confirmationCode?: string;
  token?: string;
  action?: string;
  locale?: string;
}

export interface AppointmentReservationResponse {
  confirmationCode: string;
}

/**
 * Payload to Hold an appointment used for /reserve/hold endpoint
 */
export interface AppointmentHoldRequest {
  campaignId: string;
  locationId: string;
  date: string;
  vaccineCode: string;
  additionalVaccineCodes?: string[];
  appointmentId: string;
  locale: string;
}

export interface AppointmentHoldResponse {
  locationGroupId: string;
  vaccineCode: string;
  additionalVaccineCodes?: string[];
  holdId: string;
  holdExpiration: string;
  termsAndConditionsPath: string;
  privacyPolicyPath: string;
  questions: Question[];
}
