import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { LocationIntegrationLevelBaseComponent } from '../location-integration-level-base/location-integration-level-base.component';

@Component({
    selector: 'app-location-integration-level-two',
    imports: [CommonModule, LocationIntegrationLevelBaseComponent, TranslatePipe],
    templateUrl: './location-integration-level-two.component.html',
    styleUrls: ['./location-integration-level-two.component.scss']
})
export class LocationIntegrationLevelTwoComponent extends LocationIntegrationLevelBaseComponent {}
