import { CurrentScreenType } from '../types/current-screen-type';
import { AnalyticsEventTriggerType, AnalyticsEventType } from '../types/track-event-type';
import { Location } from './location';

export interface AnalyticsEvent {
  eventType: AnalyticsEventType;
  eventTimestamp: string;
  trigger: AnalyticsEventTriggerType;
  location?: Location;
  currentScreen?: CurrentScreenType;
  age?: string;
  pregnant?: string;
  previousVaccinations?: string[];
}

export class AnalyticsEventRequest {
  eventType: AnalyticsEventType;
  eventTimestamp: string;
  trigger: AnalyticsEventTriggerType;
  campaignId: string;
  vaccineCode: string;
  additionalVaccineCodes?: string[] | undefined;
  currentScreen?: CurrentScreenType;
  age?: string;
  pregnant?: string;
  previousVaccinations?: string[];
  locationGroupId?: string;
  locationId?: string;
  locationName?: string;
  locationAddress?: string;
  locationCity?: string;
  locationState?: string;
  locationZip?: string;
  date?: string; // Appointment Date/Time in UTC format

  constructor(
    eventType: AnalyticsEventType,
    eventTimestamp: string,
    trigger: AnalyticsEventTriggerType,
    campaignId: string,
    vaccineCode: string,
    additionalVaccineCodes?: string[] | undefined,
    currentScreen?: CurrentScreenType,
    age?: string,
    pregnant?: string,
    previousVaccinations?: string[],
    locationGroupId?: string,
    locationId?: string,
    locationName?: string,
    locationAddress?: string,
    locationCity?: string,
    locationState?: string,
    locationZip?: string,
    date?: string
  ) {
    this.eventType = eventType;
    this.eventTimestamp = eventTimestamp;
    this.trigger = trigger;
    this.currentScreen = currentScreen;
    this.age = age;
    this.pregnant = pregnant;
    this.previousVaccinations = previousVaccinations;
    this.locationGroupId = locationGroupId;
    this.locationId = locationId;
    this.locationName = locationName;
    this.locationAddress = locationAddress;
    this.locationCity = locationCity;
    this.locationState = locationState;
    this.locationZip = locationZip;
    this.vaccineCode = vaccineCode;
    this.additionalVaccineCodes = additionalVaccineCodes;
    this.campaignId = campaignId;
    this.date = date;
  }
}
