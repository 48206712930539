import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { AlertPromptReminderComponent } from './alert-prompt-reminder/alert-prompt-reminder.component';
import { AlertPromptAbandonmentService } from 'src/app/common/services/alert-prompt/alert-prompt-abandonment.service';
import { AlertPromptReminder } from 'src/app/common/model/alert-prompt/alert-prompt-reminder';
import { AbandonmentService } from 'src/app/common/services/abandonment/abandonment.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DateUtil } from 'src/app/common/utils/date';
import { TermsAndPrivacyModalComponent } from '../terms-and-privacy-modal/terms-and-privacy-modal.component';
import { ModalType } from 'src/app/common/types/modal-type';
import { trigger, transition, useAnimation } from '@angular/animations';
import { FADE_IN_ANIMATION, FADE_OUT_ANIMATION } from 'src/app/common/constants/animations';
import { LoadingComponent } from '../loading/loading.component';

@Component({
  selector: 'app-alert-prompt',
  imports: [
    CommonModule,
    TranslatePipe,
    AlertPromptReminderComponent,
    forwardRef(() => TermsAndPrivacyModalComponent),
    LoadingComponent,
  ],
  templateUrl: './alert-prompt.component.html',
  styleUrls: ['./alert-prompt.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [useAnimation(FADE_IN_ANIMATION)]),
      transition(':leave', [useAnimation(FADE_OUT_ANIMATION)]),
    ]),
  ],
})
export class AlertPromptComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() titleTC?: string;
  @Input() subtitleTC?: string;
  @Input() cancelTextTC?: string;
  @Input() continueTextTC?: string;
  @Input() continueButtonDisabled: boolean | null = false;
  @Input() disclaimerTC?: string;
  @Input() disclaimerRecaptchaTC?: string;
  @Output() cancelAction: EventEmitter<void> = new EventEmitter();
  @Output() continueAction: EventEmitter<void> = new EventEmitter();

  futureDay1?: string;
  futureDay2?: string;
  safeDisclaimerHtml: SafeHtml = '';
  safeDisclaimerRecaptchaHtml: SafeHtml = '';
  showTermsAndPrivacyModal = false;
  modalType: ModalType = 'prebooking-terms';

  private termsAnchorListener?: () => void;
  private privacyAnchorListener?: () => void;

  public cancel() {
    this.cancelAction.emit();
  }

  public continue() {
    this.continueAction.emit();
  }

  get alertIsReminder(): boolean {
    return this.alertPromptAbandonmentService.alertPrompt instanceof AlertPromptReminder;
  }

  get isValid() {
    if (this.alertPromptAbandonmentService.alertPrompt instanceof AlertPromptReminder) {
      return this.abandonmentService.form.valid;
    }

    return true;
  }

  constructor(
    private alertPromptAbandonmentService: AlertPromptAbandonmentService,
    private abandonmentService: AbandonmentService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.setModalState();
    this.setDisclaimerSafeHtml();
    this.setDays();
  }

  ngOnDestroy() {
    // remove the data-modal-open attribute so content can scroll again
    document.body.removeAttribute('data-modal-open');
    this.markElementsAsInert(false);

    if (this.termsAnchorListener) {
      this.termsAnchorListener(); // remove the listener by calling the function returned by renderer.listen()
    }

    if (this.privacyAnchorListener) {
      this.privacyAnchorListener(); // remove the listener by calling the function returned by renderer.listen()
    }
  }

  ngAfterViewInit(): void {
    const termsAnchor = this.getElementContainer().getElementById('terms-reminder');
    if (termsAnchor) {
      this.termsAnchorListener = this.renderer.listen(termsAnchor, 'click', (event: Event) => {
        event.preventDefault(); // prevent the window from reloading because of the # href
        this.showTerms();
      });
    }

    const privacyAnchor = this.getElementContainer().getElementById('privacy-reminder');
    if (privacyAnchor) {
      this.privacyAnchorListener = this.renderer.listen(privacyAnchor, 'click', (event: Event) => {
        event.preventDefault(); // prevent the window from reloading because of the # href
        this.showPrivacy();
      });
    }
  }

  getElementContainer() {
    return this.el.nativeElement.getRootNode();
  }

  /**
   * Terms and Privacy Modal Handling
   */
  dismissTermsAndPrivacyModal() {
    this.setShowTermsAndPrivacyModal(false);
  }

  setModalState() {
    // set modal-open on html so the content below the modal doesn't scroll
    document.body.setAttribute('data-modal-open', 'true');
    this.markElementsAsInert(true);
  }

  setModalType(type: ModalType) {
    this.modalType = type;
  }

  // Shows or Hides the Privacy Policy or Terms and Conditions Modal
  // true: modal displays
  // false: modal hides
  setShowTermsAndPrivacyModal(shouldShow: boolean) {
    this.showTermsAndPrivacyModal = shouldShow;
  }

  /**
   * Opens the terms and conditions URL the server provided
   */
  showTerms() {
    if (this.showTermsAndPrivacyModal) {
      this.dismissTermsAndPrivacyModal();
    }

    setTimeout(() => {
      this.setModalType('prebooking-terms');
      this.setShowTermsAndPrivacyModal(true);
    }, 1);
  }

  showPrivacy() {
    if (this.showTermsAndPrivacyModal) {
      this.dismissTermsAndPrivacyModal();
    }

    setTimeout(() => {
      this.setModalType('prebooking-privacy');
      this.setShowTermsAndPrivacyModal(true);
    }, 1);
  }

  // The days for the reminder subtitle
  private setDays() {
    if (this.alertIsReminder) {
      this.futureDay1 = DateUtil.dynamicDaysFromNow(3);
      this.futureDay2 = DateUtil.dynamicDaysFromNow(7);
    }
  }

  private setDisclaimerSafeHtml() {
    if (this.disclaimerTC) {
      const translatedHtml = this.translateService.instant(this.disclaimerTC);
      this.safeDisclaimerHtml = this.sanitizer.bypassSecurityTrustHtml(translatedHtml);
    }

    if (this.disclaimerRecaptchaTC) {
      const translatedHtml = this.translateService.instant(this.disclaimerRecaptchaTC);
      this.safeDisclaimerRecaptchaHtml = this.sanitizer.bypassSecurityTrustHtml(translatedHtml);
    }
  }

  private markElementsAsInert(inert: boolean) {
    const elementsToInert = document.querySelectorAll('[appInert]');

    elementsToInert.forEach((element) => {
      element.setAttribute('appInert', inert.toString());
    });
  }
}
