import { Injectable } from '@angular/core';
import { AlertPromptService } from './alert-prompt.service';
import { AlertPromptBase } from '../../model/alert-prompt/alert-prompt-base';

@Injectable({
  providedIn: 'root',
})
export class AlertPromptAbandonmentService extends AlertPromptService {
  alertPrompt?: AlertPromptBase<any>;

  constructor() {
    super();
  }

  override openAlertPrompt() {
    super.openAlertPrompt();
  }

  override hideAlertPrompt() {
    super.hideAlertPrompt();

    this.clearAlertPrompt();
  }

  private clearAlertPrompt() {
    this.alertPrompt = undefined;
  }

  setAlertPrompt(prompt: AlertPromptBase<any>) {
    this.alertPrompt = prompt;
  }
}
