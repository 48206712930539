import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { EnvironmentService } from '../services/environment/environment.service';

export function requestInterceptor(
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> {
  const environmentService = inject(EnvironmentService); // Use Angular's DI system to inject service

  req = req.clone({
    setHeaders: {
      onramp: 'web',
      'onramp-version': environmentService.onrampVersion,
      apiKey: environmentService.apiKey,
      'user-id': environmentService.userId,
    },
  });

  return next(req).pipe(catchError((error) => throwError(() => error)));
}
