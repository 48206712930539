import { AlertPromptBase } from './alert-prompt-base';

export class AlertPromptReminder extends AlertPromptBase<string> {
  override titleTC? = 'REMINDERS.TITLE';
  override subtitleTC? = 'REMINDERS.SUBTITLE';
  override cancelTextTC? = 'REMINDERS.CANCEL_BUTTON';
  override continueTextTC? = 'REMINDERS.REMIND_ME_BUTTON';
  override disclaimerTC? = 'REMINDERS.DISCLAIMER';
  override disclaimerRecaptchaTC? = 'REMINDERS.RECAPTCHA_DISCLAIMER';
}
