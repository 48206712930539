<main class="container py-4 px-3" [appInert]="showTermsAndPrivacyModal">
  <ng-container *ngIf="(loading$ | async) === true">
    <div class="d-flex flex-column gap-2 placeholder-text-block col-12 placeholder-glow pt-3">
      <span class="placeholder bg-light-gray col-8 col-md-5 placeholder-lg"></span>

      <div class="my-3">
        <span class="placeholder bg-light-gray col-12 placeholder-sm"></span>
        <span class="placeholder bg-light-gray col-12 placeholder-sm"></span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(loading$ | async) === false">
    <header>
      <h1 class="text-primary pt-3">
        {{
          didFail
            ? ('REMINDERS.UNSUBSCRIBE_FAILURE_TITLE' | translate)
            : ('REMINDERS.UNSUBSCRIBE_CONFIRMATION_TITLE' | translate)
        }}
      </h1>

      <p class="text-tertiary my-3">
        {{
          didFail
            ? ('REMINDERS.UNSUBSCRIBE_FAILURE_SUBTITLE' | translate)
            : ('REMINDERS.UNSUBSCRIBE_CONFIRMATION_SUBTITLE' | translate)
        }}
      </p>
    </header>
  </ng-container>
</main>

<div class="container text-center" *ngIf="!suppressTermsAndPrivacy">
  <div class="d-flex flex-row justify-content-center align-items-center">
    <button
      class="btn btn-link-primary text-medium"
      (click)="showTerms()"
      [innerText]="'APPOINTMENT.SEARCH.TERMS_CONDITIONS_BUTTON' | translate"
    ></button>

    <div class="divider"></div>

    <button
      class="btn btn-link-primary text-medium"
      (click)="showPrivacy()"
      [innerText]="'APPOINTMENT.SEARCH.PRIVACY_POLICY_BUTTON' | translate"
    ></button>
  </div>
</div>

<app-terms-and-privacy-modal
  *ngIf="showTermsAndPrivacyModal"
  [modalType]="modalType"
  [@fadeIn]="showTermsAndPrivacyModal"
  (dismissModal)="dismissTermsAndPrivacyModal()"
  (emitShowTerms)="showTerms($event)"
  (emitShowPrivacy)="showPrivacy($event)"
></app-terms-and-privacy-modal>
