<div id="alert-prompt" class="modal p-3" aria-modal="true" role="dialog">
  <h2 *ngIf="titleTC" class="text-primary">{{ titleTC | translate }}</h2>
  <p *ngIf="subtitleTC" class="text-primary m-0">
    {{
      alertIsReminder
        ? (subtitleTC | translate : { futureDay1: futureDay1, futureDay2: futureDay2 })
        : (subtitleTC | translate)
    }}
  </p>

  <ng-container *ngIf="disclaimerTC">
    <div id="terms-and-privacy" class="text-primary mt-3" [innerHTML]="safeDisclaimerHtml"></div>
  </ng-container>

  <app-alert-prompt-reminder *ngIf="alertIsReminder"></app-alert-prompt-reminder>

  <div class="d-flex justify-content-between gap-3 pt-4">
    <button
      *ngIf="cancelTextTC"
      tabindex="0"
      (click)="cancel()"
      [innerText]="cancelTextTC | translate"
      class="btn btn-outline-primary text-uppercase d-flex flex-column flex-fill align-items-center m-auto justify-content-center"
    ></button>
    <button
      *ngIf="continueTextTC"
      tabindex="0"
      (click)="continue()"
      [disabled]="!isValid || continueButtonDisabled"
      class="btn btn-primary text-uppercase d-flex flex-column flex-fill align-items-center w-100 m-auto justify-content-center"
    >
      <app-loading *ngIf="continueButtonDisabled"></app-loading>
      <span *ngIf="!continueButtonDisabled" [innerText]="continueTextTC | translate"> </span>
    </button>
  </div>

  <ng-container *ngIf="disclaimerRecaptchaTC">
    <div class="text-tertiary mt-4" [innerHTML]="safeDisclaimerRecaptchaHtml"></div>
  </ng-container>
</div>

<app-terms-and-privacy-modal
  *ngIf="showTermsAndPrivacyModal"
  [modalType]="modalType"
  [@fadeIn]="showTermsAndPrivacyModal"
  (dismissModal)="dismissTermsAndPrivacyModal()"
  (emitShowTerms)="showTerms()"
  (emitShowPrivacy)="showPrivacy()"
></app-terms-and-privacy-modal>
