import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { FormsModule } from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { AppInitService } from 'src/app/app-init.service';
import { Language } from 'src/app/common/model/language';
import { ListSelectComponent } from '../list-select/list-select.component';
import { ListSelectItem } from 'src/app/common/model/list-select';
import { FooterComponent } from '../footer/footer.component';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    selector: 'app-language-picker',
    imports: [CommonModule, FormsModule, TranslatePipe, ListSelectComponent, FooterComponent],
    templateUrl: './language-picker.component.html',
    styleUrls: ['./language-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguagePickerComponent implements OnInit, OnDestroy {
  languages: Language[];
  selectedLanguage = '';

  private loadingObj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading$: Observable<boolean>;
  set loading(isLoading: boolean) {
    this.loadingObj.next(isLoading);
  }
  get loading(): boolean {
    return this.loadingObj.getValue();
  }

  get rightButtonName(): string {
    return this.translateService.instant('LANGUAGE.BUTTON');
  }

  constructor(
    private environmentService: EnvironmentService,
    private appInitService: AppInitService,
    private translateService: TranslateService
  ) {
    this.loading$ = this.loadingObj.asObservable();

    const { locale, supportedLocales } = this.environmentService;
    this.languages = supportedLocales;
    this.selectedLanguage = locale;
  }

  ngOnInit(): void {
    // set modal-open on html so the content below the modal doesn't scroll
    document.body.setAttribute('data-modal-open', 'true');
  }

  ngOnDestroy(): void {
    // remove the data-modal-open attribute so content can scroll again
    document.body.removeAttribute('data-modal-open');
  }

  public async setLanguage() {
    this.loading = true;
    this.environmentService.isLocaleOverride = true;
    this.environmentService.locale = this.selectedLanguage;

    // init the app again because we have to call the config service again
    try {
      await this.appInitService.init(false);
      this.loading = false;
      this.environmentService.hideLanguagePicker();
      // Don't configureUrl() to stay on the same path, if we need to handle config changes uncomment the code below
      // or if we need to catch the config error in the catch below potentially configureUrl there to handle error routing
      // .then(() => this.appInitService.configureUrl());
    } catch (error) {
      console.log(error);
      this.loading = false;
      this.environmentService.hideLanguagePicker();
    }
  }

  public getItems() {
    return this.languages.map((item) => {
      return new ListSelectItem(
        item.localeNativeTranslation,
        '',
        this.selectedLanguage === item.localeCode,
        item.localeCode
      );
    });
  }

  public setItem(event: ListSelectItem | null) {
    if (event) {
      this.selectedLanguage = event.code;
    } else {
      this.selectedLanguage = '';
    }
  }
}
