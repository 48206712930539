import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { AppointmentReservation } from 'src/app/common/model/appointment';
import { DateUtil } from 'src/app/common/utils/date';
import { AppointmentService } from 'src/app/common/services/appointment/appointment.service';
import { Location } from '../../common/model/location';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { LocationAddressComponent } from '../location-address/location-address.component';
import { LocationContactsComponent } from '../location-contacts/location-contacts.component';
import { EventService } from 'src/app/common/services/event/event.service';
import { AlertPromptComponent } from '../alert-prompt/alert-prompt.component';
import { AlertPromptExternalUrlService } from 'src/app/common/services/alert-prompt/alert-prompt-external-url.service';
import { AlertPromptBase } from 'src/app/common/model/alert-prompt/alert-prompt-base';
import { InertDirective } from 'src/app/directives/inert/inert.directive';

@Component({
    selector: 'app-appointment-detail-card',
    imports: [
        CommonModule,
        TranslatePipe,
        LocationAddressComponent,
        LocationContactsComponent,
        AlertPromptComponent,
        InertDirective,
    ],
    templateUrl: './appointment-detail-card.component.html',
    styleUrls: ['./appointment-detail-card.component.scss']
})
export class AppointmentDetailCardComponent implements OnInit, OnDestroy {
  isAlertPromptVisible$: Observable<boolean>;
  localeSubscription: Subscription = new Subscription();

  location: Location;
  prettyDate = '';
  prettyTime = '';
  reservation?: AppointmentReservation;
  selectedVaccineNames = '';

  get alertPrompt(): AlertPromptBase<any> | undefined {
    return this.alertPromptExternalUrlService.alertPrompt;
  }

  constructor(
    private translateService: TranslateService,
    private appointmentService: AppointmentService,
    private environmentService: EnvironmentService,
    private eventService: EventService,
    private alertPromptExternalUrlService: AlertPromptExternalUrlService
  ) {
    this.isAlertPromptVisible$ = this.alertPromptExternalUrlService.getAlertPromptVisibility();

    this.location = this.appointmentService.selectedAppointment?.location as Location;
    this.reservation = this.appointmentService.reservation;

    // listen for locale changed so we can update the time UI with the proper locale format
    this.localeSubscription = this.environmentService.localeChanged?.subscribe(() => {
      this.ngOnInit();
    });
  }

  ngOnInit() {
    const date = this.reservation?.date;
    if (date) {
      // set prettyDate based on reservation date
      const formattedDate = DateUtil.formatDateIsoToYearMonthDay(date);
      this.prettyDate = DateUtil.formatDateLong(formattedDate);

      // set prettyTime based on reservation date
      const formattedTime = DateUtil.formatTime(date);
      const localTimeZone = DateUtil.localTimeZoneAbbr;
      this.prettyTime = `${formattedTime} ${localTimeZone}`;
    }

    this.setSelectedVaccineNames();
  }

  ngOnDestroy(): void {
    if (this.localeSubscription) {
      this.localeSubscription.unsubscribe();
    }
  }

  setSelectedVaccineNames() {
    const { selectedVaccine } = this.environmentService;
    const { selectedAdditionalVaccines } = this.appointmentService;

    const selectedVaccineNames: string[] = [];

    // Add the selected vaccine name if it exists
    if (selectedVaccine?.vaccineNameTC) {
      selectedVaccineNames.push(this.translateService.instant(selectedVaccine.vaccineNameTC));
    }

    // Add additional vaccines if they exist
    if (selectedAdditionalVaccines) {
      selectedVaccineNames.push(
        ...selectedAdditionalVaccines
          .map((vaccine) =>
            vaccine.vaccineNameTC ? this.translateService.instant(vaccine.vaccineNameTC) : null
          )
          .filter((name) => name !== null) // Filter out any null values
      );
    }

    // Join the names into a single comma-delimited string
    this.selectedVaccineNames = selectedVaccineNames.join(', ');
  }

  addToCalendar() {
    const { location, reservation } = this;
    if (reservation?.date && location) {
      const address = `${location.address}, ${location.city}, ${location.state} ${location.zip}`;
      const title = this.translateService.instant('APPOINTMENT.CONFIRMATION.CALENDAR_EVENT_TITLE', {
        vaccineName: this.selectedVaccineNames,
      });
      const startDate = DateUtil.formatDateFromIso(reservation.date);
      const endDate = DateUtil.formatDateFromIsoAddingMinutes(
        reservation.date,
        reservation.duration
      );

      const event = {
        title: title,
        description: location.locationName,
        start: startDate,
        end: endDate,
        location: address,
        url: location?.informationalUrl ?? '',
      };

      const startYear = startDate.year;
      const startMonth = startDate.month;
      const startDay = startDate.day;
      const startHour = startDate.hour;
      const startMinute = startDate.minute;
      const startSecond = startDate.second;

      const endYear = endDate.year;
      const endMonth = endDate.month;
      const endDay = endDate.day;
      const endHour = endDate.hour;
      const endMinute = endDate.minute;
      const endSecond = endDate.second;

      const calString =
        `BEGIN:VCALENDAR\r\n` +
        `VERSION:2.0\r\n` +
        `BEGIN:VEVENT\r\n` +
        `SUMMARY:${event.title}\r\n` +
        `DESCRIPTION:${event.description}\r\n` +
        `LOCATION:${event.location}\r\n` +
        `URL:${event.url}\r\n` +
        `DTSTART:${startYear}${startMonth.toString().padStart(2, '0')}${startDay
          .toString()
          .padStart(2, '0')}T${startHour.toString().padStart(2, '0')}${startMinute
          .toString()
          .padStart(2, '0')}${startSecond.toString().padStart(2, '0')}\r\n` +
        `DTEND:${endYear}${endMonth.toString().padStart(2, '0')}${endDay
          .toString()
          .padStart(2, '0')}T${endHour.toString().padStart(2, '0')}${endMinute
          .toString()
          .padStart(2, '0')}${endSecond.toString().padStart(2, '0')}\r\n` +
        `END:VEVENT\r\n` +
        `END:VCALENDAR`;

      const blob = new Blob([calString], { type: 'text/calendar' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a') as HTMLAnchorElement;
      link.href = url;
      link.download = `${event.title}.ics`;
      link.click();
      window.URL.revokeObjectURL(url);
    }
  }

  /**
   * Analytics Event Handling
   */
  sendAnalyticsEvent() {
    this.eventService.sendAnalyticsEvent();
  }

  /**
   * Alert Prompt Handling
   */
  public alertPromptCancel() {
    this.hideAlertPrompt();
  }

  public alertPromptContinue() {
    this.alertPromptExternalUrlService.continueToExternalUrl();

    this.eventService.sendAnalyticsEvent();

    this.hideAlertPrompt();
  }

  public openExternalUrl(url: string) {
    this.alertPromptExternalUrlService.openAlertPrompt(url);
  }

  private hideAlertPrompt() {
    this.alertPromptExternalUrlService.dismissAlertPrompt();

    this.eventService.clearAnalyticsEvent();
  }
}
