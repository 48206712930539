import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { AppointmentService } from 'src/app/common/services/appointment/appointment.service';
import { LoadingComponent } from '../loading/loading.component';
import { Observable } from 'rxjs';
import { ModalType } from 'src/app/common/types/modal-type';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { getEmbedded, getEmbeddedAssetsUrl } from 'src/app/common/constants/general';

@Component({
    selector: 'app-terms-and-privacy-modal',
    imports: [CommonModule, TranslatePipe, LoadingComponent],
    templateUrl: './terms-and-privacy-modal.component.html',
    styleUrls: ['./terms-and-privacy-modal.component.scss']
})
export class TermsAndPrivacyModalComponent implements OnInit, OnDestroy {
  @Input() modalType: ModalType = 'terms'; // 'terms' or 'privacy'
  @Output() dismissModal = new EventEmitter<[string, string]>();
  @Output() emitShowTerms = new EventEmitter<boolean>();
  @Output() emitShowPrivacy = new EventEmitter<boolean>();
  error = '';
  htmlBody: SafeHtml = '';
  loading = false;
  closeIconUrl = 'assets/icons/close.svg';

  private termsAnchorListener?: () => void;
  private privacyAnchorListener?: () => void;

  constructor(
    private appointmentService: AppointmentService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.getHtmlBody(() => this.appointmentService.getHtmlBody(this.modalType));

    // set modal-open on html so the content below the modal doesn't scroll
    document.body.setAttribute('data-modal-open', 'true');
    this.markElementsAsInert(true);

    if (getEmbedded()) {
      this.closeIconUrl = `${getEmbeddedAssetsUrl()}/assets/icons/close.svg`;
    }
  }

  ngOnDestroy() {
    // remove the data-modal-open attribute so content can scroll again
    document.body.removeAttribute('data-modal-open');
    this.markElementsAsInert(false);

    if (this.termsAnchorListener) {
      this.termsAnchorListener(); // remove the listener by calling the function returned by renderer.listen()
    }

    if (this.privacyAnchorListener) {
      this.privacyAnchorListener(); // remove the listener by calling the function returned by renderer.listen()
    }
  }

  private markElementsAsInert(inert: boolean) {
    const elementsToInert = document.querySelectorAll('[appInert]');

    elementsToInert.forEach((element) => {
      element.setAttribute('appInert', inert.toString());
    });
  }

  /**
   * Emits the dismiss event to hide the terms modal
   */
  dismiss() {
    this.dismissModal.emit();
  }

  getHtmlBody(func: () => Observable<string>) {
    this.error = '';
    this.showLoading();

    func().subscribe({
      next: (res: string) => {
        this.handleSuccess(res);
      },
      error: (err: Error) => {
        this.handleError(err);
      },
    });
  }

  handleSuccess(res: string) {
    this.setHtmlBody(res);
    this.hideLoading();
  }

  handleError(err: Error) {
    this.error = err.message;
    this.hideLoading();
  }

  getElementContainer() {
    return this.el.nativeElement.getRootNode();
  }

  setHtmlBody(body: string) {
    this.htmlBody = this.sanitizer.bypassSecurityTrustHtml(body);

    // Wait for dom to update
    setTimeout(() => {
      const preBookingTermsAnchor = this.getElementContainer().getElementById('prebooking-terms');
      if (preBookingTermsAnchor) {
        this.termsAnchorListener = this.renderer.listen(
          preBookingTermsAnchor,
          'click',
          (event: Event) => {
            event.preventDefault(); // prevent the window from reloading because of the # href
            this.emitShowTerms.emit(true);
          }
        );
      }

      const preBookingPrivacyAnchor =
        this.getElementContainer().getElementById('prebooking-privacy');
      if (preBookingPrivacyAnchor) {
        this.privacyAnchorListener = this.renderer.listen(
          preBookingPrivacyAnchor,
          'click',
          (event: Event) => {
            event.preventDefault(); // prevent the window from reloading because of the # href
            this.emitShowPrivacy.emit(true);
          }
        );
      }

      const termsAnchor = this.getElementContainer().getElementById('postbooking-terms');
      if (termsAnchor) {
        this.termsAnchorListener = this.renderer.listen(termsAnchor, 'click', (event: Event) => {
          event.preventDefault(); // prevent the window from reloading because of the # href
          this.emitShowTerms.emit(true);
        });
      }

      const privacyAnchor = this.getElementContainer().getElementById('postbooking-privacy');
      if (privacyAnchor) {
        this.privacyAnchorListener = this.renderer.listen(
          privacyAnchor,
          'click',
          (event: Event) => {
            event.preventDefault(); // prevent the window from reloading because of the # href
            this.emitShowPrivacy.emit(true);
          }
        );
      }
    }, 1);
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }
}
