import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { AppInitService } from '../app-init.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoadingComponent } from '../components/loading/loading.component';
import { Title } from '@angular/platform-browser';
import { getEmbedded, getEmbeddedAssetsUrl } from '../common/constants/general';

@Component({
    selector: 'app-config-error',
    imports: [CommonModule, TranslatePipe, LoadingComponent],
    templateUrl: './config-error.component.html',
    styleUrls: ['./config-error.component.scss']
})
export class ConfigErrorComponent implements OnInit {
  awwShucksImageUrl = 'assets/awww-shucks.svg';
  loadingObj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading$: Observable<boolean>;
  set loading(isLoading: boolean) {
    this.loadingObj.next(isLoading);
  }
  get loading(): boolean {
    return this.loadingObj.getValue();
  }

  constructor(
    private appInitService: AppInitService,
    private translateService: TranslateService,
    private titleService: Title
  ) {
    this.loading$ = this.loadingObj.asObservable();
  }

  ngOnInit() {
    if (!getEmbedded()) {
      this.titleService.setTitle(this.translateService.instant('PAGES.ERROR.TITLE'));
    } else {
      this.awwShucksImageUrl = `${getEmbeddedAssetsUrl()}/assets/awww-shucks.svg`;
    }
  }

  async retry() {
    this.loading = true;

    // Init the app again without configuring environment
    await this.appInitService
      .init(false)
      .catch((error) => {
        this.loading = false;
      })
      .then(() => this.appInitService.configureUrl());
  }
}
