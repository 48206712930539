import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertPromptService {
  private isAlertPromptVisible = new BehaviorSubject<boolean>(false);

  getAlertPromptVisibility(): Observable<boolean> {
    return this.isAlertPromptVisible.asObservable();
  }

  isAlertActive(): boolean {
    return this.isAlertPromptVisible.getValue();
  }

  dismissAlertPrompt() {
    this.hideAlertPrompt();
  }

  openAlertPrompt(url?: string) {
    this.showAlertPrompt();
  }

  hideAlertPrompt() {
    this.isAlertPromptVisible.next(false);
  }

  showAlertPrompt() {
    this.isAlertPromptVisible.next(true);
  }
}
