import { ReminderRequestType } from '../constants/general';

export class Reminder {
  additionalVaccineCodes: string[] | undefined;
  campaignId: string;
  email: string | undefined;
  locale: string;
  locationId: string | undefined;
  phone: string | undefined;
  requestType: ReminderRequestType;
  vaccineCode: string | undefined;
  action: string; // recaptcha action
  token: string; // recaptcha token

  constructor(
    additionalVaccineCodes: string[] | undefined,
    campaignId: string,
    email: string | undefined,
    locale: string,
    locationId: string | undefined,
    phone: string | undefined,
    requestType: ReminderRequestType,
    vaccineCode: string | undefined,
    action: string, // recaptcha action
    token: string // recaptcha token
  ) {
    this.additionalVaccineCodes = additionalVaccineCodes;
    this.campaignId = campaignId;
    this.email = email;
    this.locale = locale;
    this.locationId = locationId;
    this.phone = phone;
    this.requestType = requestType;
    this.vaccineCode = vaccineCode;
    this.action = action;
    this.token = token;
  }
}
