import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { getEmbedded, ReminderRequestType } from 'src/app/common/constants/general';
import { AbandonmentService } from 'src/app/common/services/abandonment/abandonment.service';

@Component({
  selector: 'app-remind-me-button',
  imports: [CommonModule, TranslatePipe],
  templateUrl: './remind-me-button.component.html',
  styleUrl: './remind-me-button.component.scss',
})
export class RemindMeButtonComponent {
  isEmbedded = false;

  constructor(private abandonmentService: AbandonmentService) {
    this.isEmbedded = getEmbedded();
  }

  public presentReminderAlert() {
    this.abandonmentService.setAndShowAlertPrompt(
      'abandonment-reminder',
      ReminderRequestType.USER_REQUESTED
    );
  }
}
