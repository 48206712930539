import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'app-footer-branding',
    imports: [CommonModule, TranslatePipe],
    templateUrl: './footer-branding.component.html',
    styleUrls: ['./footer-branding.component.scss']
})
export class FooterBrandingComponent {}
