import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Location, LocationIntegrationLevel } from 'src/app/common/model/location';
import { AppointmentSelection } from 'src/app/common/model/appointment';
import { LocationIntegrationLevelOneComponent } from './location-integration-level/location-integration-level-one/location-integration-level-one.component';
import { LocationIntegrationLevelTwoComponent } from './location-integration-level/location-integration-level-two/location-integration-level-two.component';
import { LocationIntegrationLevelTwohalfComponent } from './location-integration-level/location-integration-level-twohalf/location-integration-level-twohalf.component';
import { LocationIntegrationLevelThreeComponent } from './location-integration-level/location-integration-level-three/location-integration-level-three.component';
import { LocationIntegrationLevelFourComponent } from './location-integration-level/location-integration-level-four/location-integration-level-four.component';

@Component({
    selector: 'app-location-card',
    imports: [
        CommonModule,
        LocationIntegrationLevelOneComponent,
        LocationIntegrationLevelTwoComponent,
        LocationIntegrationLevelTwohalfComponent,
        LocationIntegrationLevelThreeComponent,
        LocationIntegrationLevelFourComponent,
    ],
    templateUrl: './location-card.component.html'
})
export class LocationCardComponent implements OnInit {
  @Input() location!: Location;
  @ViewChild('extendedComponent', { static: false })
  extendedComponent?:
    | LocationIntegrationLevelTwohalfComponent
    | LocationIntegrationLevelThreeComponent
    | LocationIntegrationLevelFourComponent;

  @Output() openExternalUrl: EventEmitter<string> = new EventEmitter();
  @Output() selectedAppointment: EventEmitter<AppointmentSelection | undefined> = new EventEmitter<
    AppointmentSelection | undefined
  >();
  @Output() sendAnalyticsEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() viewLocationDetail: EventEmitter<Location> = new EventEmitter<Location>();

  // Expose the IntegrationLevel enum
  LocationIntegrationLevel = LocationIntegrationLevel;

  // Used in the template in order to display the proper integration level
  locationIntegrationLevel = LocationIntegrationLevel.Level1;

  ngOnInit(): void {
    this.setIntegrationLevel();
  }

  // Called if locale changes so times are in the proper locale
  reinitialize() {
    if (this.extendedComponent) {
      this.extendedComponent.reinitialize();
    }
  }

  /**
   * Determines what the integration level is for this location
   */
  private setIntegrationLevel() {
    const { location } = this;
    if (location) {
      this.location = new Location(location);
      this.locationIntegrationLevel = this.location.getIntegrationLevel();
    }
  }
}
