<!-- Service error -->
<ng-container *ngIf="errors">
  <div *ngFor="let error of errors; let first = first">
    <h5 class="disclaimer-error d-inline-block" [class.mt-4]="first">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.953 2C6.465 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.493 2 11.953 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.567 4 11.953 4C16.391 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z"
          fill="white"
        />
        <path d="M11 7H13V14H11V7ZM11 15H13V17H11V15Z" fill="white" />
      </svg>

      {{ error.reason }}
    </h5>
  </div>
</ng-container>

<form class="mt-4" *ngIf="form" [formGroup]="form">
  <ng-container *ngIf="!isEmail">
    <!-- PHONE -->
    <label class="text-tertiary mb-1" [attr.for]="'phone'">
      {{ 'REMINDERS.PHONE_LABEL' | translate }}
    </label>

    <div class="position-relative">
      <!-- PHONE TEXTBOX -->
      <input
        class="form-control text-primary mb-2 text-semibold"
        [attr.inputmode]="'tel'"
        [attr.aria-describedby]="'phone-error'"
        [class.error]="!isPhoneValid && isPhoneTouched"
        tabindex="0"
        autocomplete="on"
        (change)="textboxValueChanged($event)"
        [appPhoneMask]="true"
        [placeholder]="'REMINDERS.PHONE_PLACEHOLDER' | translate"
        formControlName="phone"
        id="phone"
        type="tel"
      />
      <img
        class="error-icon z-1"
        aria-hidden="true"
        *ngIf="!isPhoneValid && isPhoneTouched"
        [src]="errorIconUrl"
        [alt]="'ICON.ERROR' | translate"
      />
    </div>

    <!-- PHONE ERROR -->
    <p
      id="phone-error"
      role="alert"
      class="text-danger d-flex flex-column mb-2"
      *ngIf="!isPhoneValid && isPhoneTouched"
    >
      <span *ngIf="form.get('phone')?.hasError('invalidPhone')">
        {{ 'REMINDERS.VALIDATION_INVALID_PHONE' | translate }}
      </span>

      <span *ngIf="form.get('phone')?.hasError('maxlength')">
        {{ 'REMINDERS.VALIDATION_MAX_LENGTH' | translate }}
        {{ '(' + form.get('phone')?.errors?.['maxlength'].actualLength + '/' + form.get('phone')?.errors?.['maxlength'].requiredLength + ')'}}
      </span>

      <span *ngIf="form.get('phone')?.hasError('minlength')">
        {{ 'REMINDERS.VALIDATION_MIN_LENGTH' | translate }}
        {{ '(' + form.get('phone')?.errors?.['minlength'].actualLength + '/' + form.get('phone')?.errors?.['minlength'].requiredLength + ')'}}
      </span>
    </p>
  </ng-container>

  <ng-container *ngIf="isEmail">
    <!-- EMAIL -->
    <label class="text-tertiary mb-1" [attr.for]="'email'">
      {{ 'REMINDERS.EMAIL_LABEL' | translate }}
    </label>

    <div class="position-relative">
      <!-- EMAIL TEXTBOX -->
      <input
        class="form-control text-primary mb-2 text-semibold"
        [attr.inputmode]="'email'"
        [attr.aria-describedby]="'email-error'"
        [class.error]="!isEmailValid && isEmailTouched"
        tabindex="0"
        autocomplete="on"
        (change)="textboxValueChanged($event)"
        [placeholder]="'REMINDERS.EMAIL_PLACEHOLDER' | translate"
        formControlName="email"
        id="email"
        type="email"
      />
      <img
        class="error-icon z-1"
        aria-hidden="true"
        *ngIf="!isEmailValid && isEmailTouched"
        [src]="errorIconUrl"
        [alt]="'ICON.ERROR' | translate"
      />
    </div>

    <!-- EMAIL ERROR -->
    <p
      id="email-error"
      role="alert"
      class="text-danger d-flex flex-column mb-2"
      *ngIf="!isEmailValid && isEmailTouched"
    >
      <span *ngIf="form.get('email')?.hasError('invalidEmail')">
        {{ 'REMINDERS.VALIDATION_INVALID_EMAIL' | translate }}
      </span>
    </p>
  </ng-container>
</form>

<button class="btn btn-sm btn-clear-primary m-0 p-0" (click)="toggleEmail()">
  {{
    isEmail
      ? ('REMINDERS.USE_PHONE_BUTTON' | translate)
      : ('REMINDERS.USE_EMAIL_BUTTON' | translate)
  }}
</button>

<!-- Recaptcha error -->
<div *ngIf="recaptchaError" class="mt-4">
  <h5 class="disclaimer-error d-inline-block">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.953 2C6.465 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.493 2 11.953 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.567 4 11.953 4C16.391 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z"
        fill="white"
      />
      <path d="M11 7H13V14H11V7ZM11 15H13V17H11V15Z" fill="white" />
    </svg>

    {{ recaptchaError }}
  </h5>
</div>
