import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { AlertPromptType } from '../../types/alert-prompt-type';
import { AlertPromptBase } from '../../model/alert-prompt/alert-prompt-base';
import { AlertPromptIdle } from '../../model/alert-prompt/alert-prompt-idle';
import { AlertPromptAbandonmentService } from '../alert-prompt/alert-prompt-abandonment.service';
import { AlertPromptReminder } from '../../model/alert-prompt/alert-prompt-reminder';
import {
  BehaviorSubject,
  catchError,
  concatMap,
  Observable,
  Subscription,
  throwError,
  timer,
} from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormValidatorUtil } from '../../utils/form-validators';
import {
  API_RETRY_LIMIT,
  getEmbedded,
  ReminderRequestType,
  ServiceErrorCodes,
} from '../../constants/general';
import { RecaptchaService } from '../recaptcha/recaptcha.service';
import { TranslateService } from '@ngx-translate/core';
import { Reminder } from '../../model/reminder';
import { EnvironmentService } from '../environment/environment.service';
import { AppointmentService } from '../appointment/appointment.service';
import { HttpClient } from '@angular/common/http';
import { VaxError, VaxErrorDetail } from '../../model/error';
import { AlertPromptReminderSuccess } from '../../model/alert-prompt/alert-prompt-reminder-success';
import { RouteService } from '../route/route.service';
declare const grecaptcha: any;

@Injectable({
  providedIn: 'root',
})
export class AbandonmentService implements OnDestroy {
  isEmailObj: BehaviorSubject<boolean> = new BehaviorSubject(false); // if the user is entering their phone or email
  loadingObj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading$: Observable<boolean>;
  form = new FormGroup({
    phone: new FormControl(''),
    email: new FormControl(''),
  });
  unsubscribeParam = ''; // the param value passed from the unsubscribe email

  private _errors?: VaxErrorDetail[];
  private alertVisibilitySubscription?: Subscription;
  private idleTimeout: ReturnType<typeof setTimeout> | any;
  private requestType: ReminderRequestType = ReminderRequestType.ABANDONED;
  private readonly idleTime = 5 * 60 * 1000; // 5 minutes
  private readonly idleEvents = ['mousemove', 'keydown', 'scroll', 'touchstart'];

  constructor(
    private ngZone: NgZone,
    private http: HttpClient,
    private alertPromptAbandonmentService: AlertPromptAbandonmentService,
    private recaptchaService: RecaptchaService,
    private translateService: TranslateService,
    private environmentService: EnvironmentService,
    private appointmentService: AppointmentService,
    private routeService: RouteService
  ) {
    this.loading$ = this.loadingObj.asObservable();

    // Suppress for Embed
    if (!getEmbedded()) {
      this.startIdleTimer();
      this.subscribeToAlertVisibility();
    }
  }

  get errors(): VaxErrorDetail[] | undefined {
    return this._errors;
  }

  set errors(value: VaxErrorDetail[] | undefined) {
    this._errors = value;
  }

  get isEmail() {
    return this.isEmailObj.getValue();
  }

  set isEmail(isEmail: boolean) {
    this.isEmailObj.next(isEmail);
    this.updateValidators();
  }

  set loading(isLoading: boolean) {
    this.loadingObj.next(isLoading);
  }

  get loading(): boolean {
    return this.loadingObj.getValue();
  }

  get recaptchaRetryCount() {
    return this.recaptchaService.recaptchaRetryCount;
  }

  get recaptchaError() {
    const err = <any>this.recaptchaService.recaptchaError;

    if (!err) {
      return '';
    }

    if (err.details && err.details.length > 0) {
      return err.details[0].reason;
    }

    return '';
  }

  clearErrors() {
    this.errors = undefined;
  }

  /**
   * Unsubscribes a user from reminders
   * @returns
   */
  getUnsubscribe(): Observable<any> {
    const { apiBaseUrl } = this.environmentService;
    const url = `${apiBaseUrl}/remind/unsubscribe?u=${this.unsubscribeParam}`;

    let retryCount = 0;

    return this.http.get<any>(url).pipe(
      catchError((error, caught) => {
        if (error.status === 500 && retryCount < API_RETRY_LIMIT) {
          retryCount++;
          console.warn(`Retrying remind unsubscribe... Attempt ${retryCount}`);
          return timer(1000).pipe(concatMap(() => caught));
        }

        console.error('Max retries reached or non-500 error encountered.');
        return throwError(() => error);
      })
    );
  }

  // Called from the app init service to set the query param from the incomping email hyperlink
  handleUnsubscribe(u: string) {
    this.unsubscribeParam = u;
  }

  handleServiceError(error?: VaxError) {
    if (!error || error === undefined || (error as any).status === 500) {
      return;
    }

    // Process the error
    const { details } = this.processError(error);

    // Set errors
    this.setErrors(details);
  }

  setErrors(errors: VaxErrorDetail[] | undefined) {
    this.errors = errors;
  }

  processError(error: VaxError): { details: VaxErrorDetail[] } {
    let msg = '';
    const errorDetail = error.details || [];
    const details: VaxErrorDetail[] = [];

    switch (error.subCode) {
      case ServiceErrorCodes.SUBCODE_VALIDATION_ERROR: {
        msg = this.translateService.instant('ERRORS.1000.MESSAGE');
        errorDetail.forEach((detail: VaxErrorDetail) => {
          const reason = detail.reason ?? '';
          details.push({
            name: detail.name,
            reason: `${msg} (${detail.name}: ${reason})`,
            displayInline: false,
          });
        });
        break;
      }
      case ServiceErrorCodes.SUBCODE_RECAPTCHA_ERROR: {
        msg = this.translateService.instant('ERRORS.1004.MESSAGE');
        errorDetail.forEach((detail: VaxErrorDetail) => {
          details.push({
            name: detail.name,
            reason: msg,
            displayInline: false,
          });
        });
        break;
      }
      default: {
        errorDetail.forEach((detail: VaxErrorDetail) => {
          const reason = detail.reason ?? '';
          details.push({
            name: detail.name,
            reason: reason,
            displayInline: false,
          });
        });
        break;
      }
    }

    return { details };
  }

  private resetIdleTimer = (event?: Event) => {
    clearTimeout(this.idleTimeout);
    this.idleTimeout = setTimeout(() => {
      this.ngZone.run(() => this.onIdle());
    }, this.idleTime);
  };

  private startIdleTimer() {
    this.ngZone.runOutsideAngular(() => {
      this.idleEvents.forEach((event) => {
        window.addEventListener(event, this.resetIdleTimer);
      });
    });

    this.resetIdleTimer();
  }

  private onIdle() {
    // Prevent idle alert if any alert is already active
    if (this.alertPromptAbandonmentService.isAlertActive()) {
      return;
    }

    // Prevent idle alert if on an excluded route
    if (!this.shouldShowAlert()) {
      return;
    }

    this.setAndShowAlertPrompt('abandonment-idle', ReminderRequestType.ABANDONED);
  }

  public setRequestType(requestType: ReminderRequestType) {
    this.requestType = requestType;
  }

  public setAndShowAlertPrompt(
    type: AlertPromptType,
    requestType: ReminderRequestType = ReminderRequestType.ABANDONED
  ) {
    if (this.alertPromptAbandonmentService.isAlertActive()) {
      return; // Prevent any duplicate alerts
    }

    this.requestType = requestType;

    const prompt: AlertPromptBase<any> | undefined =
      type === 'abandonment-idle'
        ? new AlertPromptIdle()
        : type === 'abandonment-reminder'
        ? new AlertPromptReminder()
        : type === 'abandonment-success'
        ? new AlertPromptReminderSuccess()
        : undefined;

    if (prompt) {
      this.alertPromptAbandonmentService.setAlertPrompt(prompt);
      this.alertPromptAbandonmentService.openAlertPrompt();
    }
  }

  private shouldShowAlert(): boolean {
    const excludedRoutes = [
      this.routeService.getUnsubscribeRoute(),
      this.routeService.getConfigErrorRoute(),
    ]; // Use dynamic routes

    const router = this.routeService.getRouter();
    const currentRoute = router.url.split('?')[0].replace(/^\/+/, ''); // Get route without the query params and without the leading slash

    return !excludedRoutes.includes(currentRoute);
  }

  /** Toggle between phone and email for the form */
  toggleEmail() {
    this.isEmail = !this.isEmail;
  }

  /**
   * Sends reminder request with retry logic
   * @param payload
   * @returns
   */
  postRequestReminder(payload: Reminder) {
    const { apiBaseUrl } = this.environmentService;
    const url = `${apiBaseUrl}/remind`;
    let retryCount = 0;

    return this.http.post<any>(url, payload).pipe(
      catchError((error, caught) => {
        if (error.status === 500 && retryCount < API_RETRY_LIMIT) {
          retryCount++;
          console.warn(`Retrying request reminder... Attempt ${retryCount}`);
          return timer(1000).pipe(concatMap(() => caught));
        }

        console.error('Max retries reached or non-500 error encountered.');
        return throwError(() => error);
      })
    );
  }

  async submitReminder() {
    // Only allow a certain amount of recaptcha retries
    if (this.recaptchaRetryCount >= this.recaptchaService.recaptchaRetryLimit) {
      return;
    }

    this.loading = true;

    if (typeof grecaptcha !== 'undefined') {
      try {
        const { recaptchaKey, recaptchaActionRemind } = this.recaptchaService;
        const token = await grecaptcha.enterprise.execute(recaptchaKey, {
          action: recaptchaActionRemind,
        });

        const { selectedAdditionalVaccineCodes, selectedAppointment } = this.appointmentService;
        const { campaignId, locale, selectedVaccine } = this.environmentService;
        const emailControl = this.form.get('email');
        const phoneControl = this.form.get('phone');

        const reminder = new Reminder(
          selectedAdditionalVaccineCodes,
          campaignId,
          emailControl?.value || undefined,
          locale,
          selectedAppointment?.locationId,
          phoneControl?.value || undefined,
          this.requestType,
          selectedVaccine?.vaccineCode,
          recaptchaActionRemind,
          token
        );

        this.postRequestReminder(reminder).subscribe({
          next: () => {
            // Clear any recaptcha errors
            this.recaptchaService.clearError();
            // Clear any service errors
            this.clearErrors();

            // Set loading state
            this.loading = false;

            // Set alert type to success
            this.alertPromptAbandonmentService.dismissAlertPrompt();
            setTimeout(() => {
              this.setAndShowAlertPrompt('abandonment-success');

              // Reset requestType
              this.setRequestType(ReminderRequestType.ABANDONED);
              // Reset form
              this.form.reset();
            });
          },
          error: (error) => {
            console.error('Reminder submission failed:', error);

            this.loading = false;

            if (error.error && error.error.subCode) {
              this.handleServiceError(error.error);
            } else {
              this.handleServiceError(error);
            }
          },
        });
      } catch (error) {
        this.loading = false;

        // Recaptcha failed
        this.recaptchaService.setError(this.translateService.instant('ERRORS.1004'));
      }
    }
  }

  /** Subscribe to alert visibility changes */
  private subscribeToAlertVisibility(): void {
    this.alertVisibilitySubscription = this.alertPromptAbandonmentService
      .getAlertPromptVisibility()
      .subscribe((isVisible) => {
        if (!isVisible) {
          // Reset the idle timer when an alert is dismissed
          this.resetIdleTimer();
        }
      });
  }

  /** Updates form validators */
  updateValidators(): void {
    const emailControl = this.form.get('email');
    const phoneControl = this.form.get('phone');

    if (this.isEmail) {
      emailControl?.setValidators([FormValidatorUtil.emailValidator()]);
      phoneControl?.clearValidators();
      phoneControl?.reset();
    } else {
      phoneControl?.setValidators([
        FormValidatorUtil.phoneValidator(),
        Validators.maxLength(12),
        Validators.minLength(12),
      ]);
      emailControl?.clearValidators();
      emailControl?.reset();
    }

    emailControl?.updateValueAndValidity();
    phoneControl?.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    clearTimeout(this.idleTimeout);

    // Remove Idle event listeners
    this.idleEvents.forEach((event) => window.removeEventListener(event, this.resetIdleTimer));

    if (this.alertVisibilitySubscription) {
      this.alertVisibilitySubscription.unsubscribe();
    }
  }
}
