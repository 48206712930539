import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { LocationIntegrationLevelBaseComponent } from '../location-integration-level-base/location-integration-level-base.component';

@Component({
    selector: 'app-location-integration-level-one',
    imports: [CommonModule, TranslatePipe, LocationIntegrationLevelBaseComponent],
    templateUrl: './location-integration-level-one.component.html',
    styleUrls: ['./location-integration-level-one.component.scss']
})
export class LocationIntegrationLevelOneComponent extends LocationIntegrationLevelBaseComponent {}
