import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-location-address',
    imports: [CommonModule],
    templateUrl: './location-address.component.html',
    styleUrls: ['./location-address.component.scss']
})
export class LocationAddressComponent {
  @Input() address?: string;
  @Input() distance?: number;
  @Input() city?: string;
  @Input() state?: string;
}
